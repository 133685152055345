<template>
  <form>
    <div
      v-if="loading"
      class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
    >
      <Loading />
    </div>
    <div class="space-y-6" v-else>
      <div>
        <h1 class="text-lg leading-6 font-medium text-gray-900">Informations générales</h1>
        <p class="mt-1 text-sm text-gray-500">
          Commençons par remplir les informations ci-dessous pour créer votre nouvel événement.
        </p>
      </div>

      <div class="w-1/4" v-if="!localEvent.account_id">
        <Combobox as="div" v-model="localEvent.account_id">
          <ComboboxLabel class="block text-sm font-medium text-gray-700"
            >Compte associé</ComboboxLabel
          >
          <div class="relative mt-1">
            <ComboboxInput
              class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
              placeholder="Sélectionnez le compte "
              @change="query = $event.target.value"
              :displayValue="(accountType) => {
                console.log(accountType)
              }"
            />
            <ComboboxButton
              class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            >
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions
              v-if="state.filteredAccountTypes.length > 0"
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ComboboxOption v-if="queryAccount" :value="queryAccount">
                Créer "{{ query }}" ?
              </ComboboxOption>
              <ComboboxOption
                v-for="accountType in state.filteredAccountTypes"
                :key="accountType.id"
                :value="accountType.slug"
                as="template"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-primary-600 text-white' : 'text-gray-900'
                  ]"
                >
                  <span :class="['block truncate', selected && 'font-semibold']">
                    {{ accountType.name }}
                  </span>

                  <span
                    v-if="selected"
                    :class="[
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                      active ? 'text-white' : 'text-primary-600'
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ComboboxOption>
            </ComboboxOptions>
          </div>
        </Combobox>
      </div>
      <div class="w-1/4" v-else>
        <label for="account_id" class="block text-sm font-medium text-gray-700">
          Compte associé
        </label>
        <input
          id="account_id"
          v-model="localEvent.account_id"
          type="text"
          readonly
          class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="name" class="block text-sm font-medium text-gray-700">
          Nom de l'événement
        </label>
        <div class="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            v-model="localEvent.name"
            class="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <fieldset class="space-y-5">
        <div class="relative flex items-start">
          <div class="flex items-center h-5">
            <input
              id="has_webinar"
              v-model="localEvent.has_webinar"
              aria-describedby="comments-description"
              name="has_webinar"
              type="checkbox"
              class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="has_webinar" class="font-medium text-gray-700"
              >Hybride ( avec webinar / diffusion )</label
            >
          </div>
        </div>
      </fieldset>

      <div>
        <label for="description" class="block text-sm font-medium text-gray-700">
          Description
        </label>
        <div class="mt-1">
          <textarea
            id="description"
            name="description"
            rows="3"
            v-model="localEvent.description"
            class="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div>
        <label for="venue" class="block text-sm font-medium text-gray-700"> Lieu </label>
        <div class="mt-1">
          <input
            type="text"
            name="venue"
            id="venue"
            v-model="localEvent.venue"
            class="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div>
        <vue-tailwind-datepicker
          separator=" au "
          :shortcuts="false"
          i18n="fr"
          :options="options"
          placeholder="Dates de l'événement"
          v-model="startEndDate"
          input-classes="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      <RadioGroup v-model="selected">
        <RadioGroupLabel class="text-sm font-medium text-gray-900">
          Type d'événement</RadioGroupLabel
        >
        <div class="mt-1 bg-white rounded-md shadow-sm -space-y-px">
          <RadioGroupOption
            as="template"
            v-for="(setting, settingIdx) in types"
            :key="setting.name"
            :value="setting"
            v-slot="{ checked, active }"
          >
            <div
              :class="[
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === types.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-primary-50 border-primary-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              ]"
            >
              <span
                :class="[
                  checked ? 'bg-primary-600 border-transparent' : 'bg-white border-gray-300',
                  active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                  'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                ]"
                aria-hidden="true"
              >
                <span class="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <div class="ml-3 flex flex-col">
                <RadioGroupLabel
                  as="span"
                  :class="[
                    checked ? 'text-primary-900' : 'text-gray-900',
                    'block text-sm font-medium'
                  ]"
                >
                  {{ setting.name }}
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  :class="[checked ? 'text-primary-700' : 'text-gray-500', 'block text-sm']"
                >
                  {{ setting.description }}
                </RadioGroupDescription>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>

      <GenericButton type="clear" @click.prevent="nextStep">
        {{ $t('pagination.next') }}
      </GenericButton>
    </div>
  </form>
</template>
<script>
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption
  } from '@headlessui/vue'

  import { SelectorIcon, CheckIcon } from '@heroicons/vue/solid'
  import { ref, onMounted, computed, reactive, watchEffect } from 'vue'
  import http from '@/http/index.js'
  import VueTailwindDatepicker from 'vue-tailwind-datepicker'
  import store from '@/store/index.js'
  import { useRoute } from 'vue-router'
  import GenericButton from '@/components/buttons/GenericButton.vue'
  import Loading from "@/components/Loading.vue"
  export default {
    components: {
      GenericButton,
      Loading,
      Combobox,
      ComboboxButton,
      ComboboxInput,
      ComboboxLabel,
      ComboboxOption,
      ComboboxOptions,
      RadioGroup,
      RadioGroupDescription,
      RadioGroupLabel,
      SelectorIcon,
      CheckIcon,
      RadioGroupOption,
      VueTailwindDatepicker
    },
    setup(props, { emit }) {
      const route = useRoute()
      const types = ref([])
      const selected = ref(null)
      const loading = ref(true)
      const startEndDate = ref([])
      const queryAccount = computed(() => {
        return query.value === '' ? null : { id: null, name: query.value }
      })
      let query = ref('')

      const options = ref({
        footer: {
          apply: 'Appliquer',
          cancel: 'Annuler'
        }
      })

      const state = reactive({
        accountTypes: store.state.accounts.accounts,
        filteredAccountTypes: computed(() =>
          query.value === ''
            ? state.accountTypes
            : state.accountTypes.filter(accountType => {
                return accountType.name.toLowerCase().includes(query.value.toLowerCase())
              })
        )
      })

      const localEvent = store.state.events.newEvent

      watchEffect(async () => {
        localEvent.account_id = route.query.account ? route.query.account : null
        localEvent.type = selected.value?.id
        localEvent.start_date = startEndDate.value[0]
        localEvent.end_date = startEndDate.value[1]
        store.dispatch('events/setEvent', localEvent)
      })

      onMounted(async () => {
        try {
          loading.value = true
          const response = await http.get('/customer/events/types')
          types.value = response.data
          if (types.value.length > 0) {
            selected.value = types.value[0]
          }
          loading.value = false
        } catch (error) {
          console.error('Error fetching types:', error)
        }
      })

      const nextStep = () => {
        emit('goToStep', 2)
      }

      return {
        localEvent,
        types,
        state,
        options,
        selected,
        loading,
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        nextStep,
        RadioGroupOption,
        startEndDate,
        queryAccount
      }
    }
  }
</script>
