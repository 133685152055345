<template>
  <div class="m-4 flex flex-col gap-3">
    <ErrorComponent v-if="errors.length" :errors="errors" />
    <div
      v-for="(key, value) in $store.state.accounts.activeEvent.settings.available_attendee_fields"
    >
      <label :for="value" class="block text-sm font-medium text-gray-700">{{
        $t('attendee.fields.' + value)
      }}</label>
      <div class="mt-1">
        <input
          type="email"
          :name="value"
          :id="value"
          class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
          v-model="attendee[value]"
        />
      </div>
    </div>

    <div class="pt-4">
      <ButtonPrimary @click="storeAttendee">Enregister</ButtonPrimary>
    </div>
  </div>
</template>

<script>
  import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
  import http from '@/http/index.js'
  import ErrorComponent from '@/components/alerts/ErrorComponent.vue'

  export default {
    components: { ErrorComponent, ButtonPrimary },
    data() {
      return {
        attendee: {},
        errors: []
      }
    },
    mounted() {},
    methods: {
      storeAttendee() {
        this.errors = []
        http
          .post('/customer/events/' + this.$route.params.id + '/attendees', {
            meta: JSON.stringify(this.attendee)
          })
          .then(() => {
            this.$store.commit('drawer/setDynamicComponent', null)
            this.$store.commit('drawer/setDrawerTitle', null)
            this.$store.commit('drawer/closeDrawer')
          })
          .catch(error => {
            this.errors.push(error.response.data)
          })
      }
    }
  }
</script>
