<template>
  <div>
    <CommandPalette :is-dialog-open="showCommandPalette" />

    <ul role="list" class="mt-6 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
      <li v-for="(item, itemIdx) in filteredActivities" :key="itemIdx" class="flow-root">
        <div
          class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
        >
          <div
            :class="[
              item.background,
              'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
            ]"
          >
            <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <div>
            <h3 class="text-sm font-medium text-gray-900">
              <a href="#" @click="showActivity(item)" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ item.title }}<span aria-hidden="true"> &rarr;</span>
              </a>
            </h3>
            <p class="mt-1 text-sm text-gray-500">{{ item.description }}</p>
          </div>
        </div>
      </li>
    </ul>

    <div class="mt-4 flex">
      <a
        href="#"
        @click="showCommandPalette = true"
        class="text-sm font-medium text-indigo-600 hover:text-indigo-500"
        >Trouver une activité <span aria-hidden="true"> &rarr;</span></a
      >
    </div>
  </div>
</template>

<script setup>
  import {
    AnnotationIcon,
    CameraIcon,
    ChartPieIcon,
    ChatAlt2Icon,
    CloudIcon,
    PencilIcon,
    PuzzleIcon,
    QuestionMarkCircleIcon,
    UserIcon
  } from '@heroicons/vue/solid'
  import { ref, watch, defineEmits } from 'vue'
  import CreatePollsForm from '@/components/events/activities/polls/CreatePollsForm.vue'
  import CommandPalette from '@/components/CommandPalette.vue'

  const emits = defineEmits(['activityClicked'])

  const activities = [
    {
      title: 'Session de Questions/Réponses',
      description: 'Engagez votre audience avec des questions et réponses.',
      icon: QuestionMarkCircleIcon,
      background: 'bg-pink-500',
      path: '/q-and-a'
    },
    {
      title: 'poll',
      description: 'Recueillez des opinions et des insights avec des sondages interactifs.',
      icon: ChartPieIcon,
      background: 'bg-yellow-500',
      path: '/polls',
      component: CreatePollsForm
    },
    {
      title: 'Quiz',
      description: 'Testez les connaissances et divertissez les participants avec des quiz.',
      icon: PuzzleIcon,
      background: 'bg-green-500',
      path: '/quiz'
    },
    {
      title: 'Nuage de Mots',
      description: 'Visualisez les réponses du public dans un nuage de mots dynamique.',
      icon: CloudIcon,
      background: 'bg-blue-500',
      path: '/word-cloud'
    },
    {
      title: "Composition d'Avatars",
      description: "Créez des avatars personnalisés pour le plaisir et l'engagement.",
      icon: UserIcon,
      background: 'bg-indigo-500',
      path: '/avatar-composition'
    },
    {
      title: "Affichage d'Abstracts",
      description: 'Présentez des résumés ou des abstracts dans un format visuellement attrayant.',
      icon: AnnotationIcon,
      background: 'bg-purple-500',
      path: '/abstract-display'
    },
    {
      title: 'Photobooth',
      description: 'Capturez des moments mémorables avec un photobooth digital ou physique.',
      icon: CameraIcon,
      background: 'bg-red-500',
      path: '/photobooth'
    },
    {
      title: 'Atelier de Création',
      description:
        'Encouragez la créativité avec des activités de dessin ou de création artistique.',
      icon: PencilIcon,
      background: 'bg-orange-500',
      path: '/creation-workshop'
    },
    {
      title: 'Débat en Direct',
      description: "Organisez des débats interactifs en temps réel sur des sujets d'actualité.",
      icon: ChatAlt2Icon,
      background: 'bg-teal-500',
      path: '/debate'
    }
  ]

  const searchTerm = ref('')
  const filteredActivities = ref(activities)
  const showCommandPalette = ref(false)

  watch(searchTerm, newTerm => {
    filteredActivities.value = activities.filter(activity =>
      activity.title.toLowerCase().includes(newTerm.toLowerCase())
    )
  })

  const showActivity = item => {
    emits('activityClicked', { activity: item, withCreate: true })
  }
</script>
