import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '../auth'
import accounts from './accounts'
import drawer from './drawer'
import toast from './toast'
import events from '@/store/events/index.js'
import notifications from '@/store/notifications/index.js'

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    accounts,
    auth,
    drawer,
    events,
    toast,
    notifications,
    appSettings: {
      namespaced: true,
      state: {
        direction: 'ltr'
      },
      mutations: {
        setDirection(state, dir) {
          state.direction = dir
        }
      },
      actions: {
        updateDirection({ commit }, dir) {
          commit('setDirection', dir)
        }
      }
    }
  }
})
