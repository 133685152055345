<template>
  <ActivitiesHome @activityClicked='handleActivityAction' v-if='!activity' />
  <div class='flex' v-else>
    {{ activity }}
  </div>
</template>
<script>
  import ActivitiesHome from '@/pages/events/activities/ActivitiesHome.vue'
  import { useI18n } from 'vue-i18n'
  import { shallowRef } from 'vue'
  import { useStore } from 'vuex'

  export default {
    components: { ActivitiesHome },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const activity = shallowRef(null)
      const isDrawerOpen = shallowRef(false)

      const handleActivityAction = (details) => {
        activity.value = details.activity
        if (details.withCreate) {
          store.commit('drawer/setDynamicComponent', activity.value.component)
          store.commit('drawer/setDrawerTitle', t('general.entityAction', {entity: t('poll'), control: t('general.controls.create')}))
          store.commit('drawer/openDrawer')
          isDrawerOpen.value = true
        }
      }

      const closeDrawer = () => {
        isDrawerOpen.value = false
      }

      return {
        activity,
        isDrawerOpen,
        handleActivityAction,
        closeDrawer
      }
    }
  }

</script>
