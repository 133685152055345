<template>

    <div class="grid grid-cols-2 grid-rows-2 gap-4">
        <div>
            <div class="rounded border-2 border-opacity-80 hover:border-opacity-100 border-gray-200 transition hover:shadow-sm p-4 flex flex-col items-center gap-4 cursor-pointer group"
                @click="showSubDrawer('landing')">
                <LandingPage class="w-20 fill-primary-600 group-hover:fill-primary-800" />
                <span class=" font-semibold text-gray-700  text-sm ">Page d'accueil</span>
            </div>
        </div>
        <div>
            <div class="rounded border-2 border-opacity-80 hover:border-opacity-100 border-gray-200 transition hover:shadow-sm p-4 flex flex-col items-center gap-4 cursor-pointer group"
                @click="showSubDrawer('badges')">
                <Badge class="w-20 fill-secondary-600 group-hover:fill-secondary-800" />
                <span class=" font-semibold text-gray-700  text-sm ">Badges</span>
            </div>
        </div>
        <div>
            <div class="rounded border-2 border-opacity-80 hover:border-opacity-100 border-gray-200 transition hover:shadow-sm p-4 flex flex-col items-center gap-4 cursor-pointer group"
                @click="showSubDrawer('emails')">
                <Emails class="w-20 fill-mantis-600 group-hover:fill-mantis-800" />
                <span class=" font-semibold text-gray-700  text-sm ">E-mails</span>
            </div>
        </div>
    </div>

</template>

<script>
import GenericButton from '../buttons/GenericButton.vue';
import LandingPage from "@/components/icons/LandingPage.vue"
import Badge from "@/components/icons/Badge.vue"
import Emails from "@/components/icons/Emails.vue"
import LandingPageEditor from './studio/LandingPageEditor.vue';
import BadgeEditor from './studio/BadgeEditor.vue';
import EmailsEditor from './studio/EmailsEditor.vue';
export default {
    components: { GenericButton, LandingPage, Badge, Emails },
    data() {
        return {
            customizables: [{
                name: "landing",
                component: LandingPageEditor
            }, {
                name: "badges",
                component: BadgeEditor
            }, {
                name: "emails",
                component: EmailsEditor
            }

            ]
        }
    },
    methods: {
        showSubDrawer(item) {
            this.customizables.filter((customizable) => {
                if (customizable.name === item) {
                    this.$emit('showSubDrawer', customizable.component);
                }
            })
        }
    }
}
</script>