<template>
  <div class="flex items-center flex-col">
    <i :class="['fas fa-3x my-4 text-gray-400', `fa-${icon}`]"></i>
    <h3 class="mt-2 text-sm font-medium text-gray-900">
      {{ $t(`general.hints.empty_state.title`, { entity: entity }) }}
    </h3>
    <p class="mt-1 text-sm text-gray-500">
      {{ $t(`general.hints.empty_state.description`, { entity: entity }) }}
    </p>
  </div>
</template>
<script>
  export default {
    props: ['entity', 'icon']
  }
</script>
