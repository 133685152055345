<template>
  <p class="mt-1 text-sm text-gray-500">
    Il n'y a pas d'événements prévus pour
    <strong>{{ $store.state.accounts.activeAccount.name }}</strong
    >, créez-en un .
  </p>
  <Suspense>
    <TransitionGroup
      name="list"
      tag="ul"
      class="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2"
    >
      <li v-for="(item, itemIdx) in types" :key="itemIdx" class="flow-root">
        <div
          class="relative gap-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-primary-500"
        >
          <div
            :class="[
              item.iconColor,
              'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
            ]"
          >
            <i :class="item.icon" class="fa-2x text-white" aria-hidden="true" />
          </div>
          <div>
            <h3 class="text-sm font-medium text-gray-900">
              <router-link
                :to="{
                  path: '/events/create',
                  query: { account: $route.params.slug, type: item.id }
                }"
                class="focus:outline-none"
              >
                <span class="absolute inset-0" aria-hidden="true" />
                {{ $t(`events.types.${item.id}.title`) }}<span aria-hidden="true"> &rarr;</span>
              </router-link>
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              {{ $t(`events.types.${item.id}.description`) }}
            </p>
          </div>
        </div>
      </li>
    </TransitionGroup>
  </Suspense>

  <div class="mt-4 flex">
    <a href="#" class="text-sm font-medium text-primary-600 hover:text-primary-500"
      >Ou commencez avec un assistant de configuration avancé<span aria-hidden="true">
        &rarr;</span
      ></a
    >
  </div>
</template>

<script>
  import http from '@/http/index.js'
  import { Suspense, TransitionGroup } from 'vue'

  export default {
    name: 'EventTypeSelector',
    components: { Suspense, TransitionGroup },
    data() {
      return {
        types: {}
      }
    },
    mounted() {
      this.fetchTypes()
    },
    methods: {
      fetchTypes() {
        http.get('/customer/events/types').then(response => {
          this.types = response.data
        })
      }
    }
  }
</script>
