<template>
  <div class="space-y-6">
    <div>
      <h1 class="text-lg leading-6 font-medium text-gray-900">Branding</h1>
      <p class="mt-1 text-sm text-gray-500">
        Personnalisez l'apparence de votre événement en définissant les couleurs et le style visuel.
      </p>
    </div>

    <div class="flex">
      <!-- Formulaire de configuration -->
      <div class="w-1/2 p-4">
        <form @submit.prevent="submitForm">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="color">{{
              $t('forms.labels.primary_color')
            }}</label>
            <input
              v-model="settings.primary_color"
              class="shadow appearance-none border rounded w-10 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
              id="primary_color"
              type="color"
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="color">{{
              $t('forms.labels.secondary_color')
            }}</label>
            <input
              v-model="settings.secondary_color"
              class="shadow appearance-none border rounded w-10 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
              id="secondary_color"
              type="color"
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="color">{{
              $t('forms.labels.accent_color')
            }}</label>
            <input
              v-model="settings.accent_color"
              class="shadow appearance-none border rounded w-10 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10"
              id="accent_color"
              type="color"
            />
          </div>
          <div class="mb-4">
            <label for="cover-photo" class="block text-sm font-medium text-gray-700"> Logo </label>
            <div
              class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
            >
              <div class="space-y-1 text-center">
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="flex text-sm text-gray-600">
                  <label
                    for="logo_url"
                    class="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>{{ $t('general.controls.upload_a_file') }}</span>
                    <input
                      @change="onFileChange('logo_url')"
                      id="logo_url"
                      type="file"
                      class="sr-only"
                    />
                  </label>
                  <p class="pl-1">or drag and drop</p>
                </div>
                <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label for="cover-photo" class="block text-sm font-medium text-gray-700">
              Image de couverture
            </label>
            <div
              class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
            >
              <div class="space-y-1 text-center">
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="flex text-sm text-gray-600">
                  <label
                    for="cover_image"
                    class="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>{{ $t('general.controls.upload_a_file') }}</span>
                    <input
                      @change="onFileChange('cover_image')"
                      id="cover_image"
                      type="file"
                      class="sr-only"
                    />
                  </label>
                  <p class="pl-1">or drag and drop</p>
                </div>
                <p class="text-xs text-gray-500">PNG, JPG, GIF jusqu'a 10MB</p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Aperçu en temps réel -->
      <div class="w-1/2 p-4 border-l">
        <div class="border rounded p-4" :style="{ backgroundColor: 'white' }">
          <div class="flex justify-between items-center mb-4">
            <img :src="logoUrl ? logoUrl : defaultLogoUrl" alt="Logo" class="h-8" />
            <div class="flex space-x-2">
              <Facebook />
              <Twitter />
            </div>
          </div>
          <div
            class="jumbotron"
            :style="{
              backgroundColor: settings.primary_color,
              backgroundImage: 'url(' + coverUrl + ')'
            }"
          >
            <h1
              class="text-center text-white text-3xl mb-4"
              :style="{ color: settings.accent_color }"
            >
              Bienvenue à notre événement
            </h1>
            <div class="text-center">
              <button
                class="bg-white text-blue-500 font-bold py-2 px-4 rounded"
                :style="{ color: settings.primary_color }"
              >
                Inscription
              </button>
            </div>
          </div>
          <div class="mt-4">
            <h2 class="text-xl mb-2">Orateurs</h2>
            <div class="grid grid-cols-3 gap-4">
              <div v-for="index in 6" :key="index" class="text-center">
                <div class="bg-gray-300 rounded-full w-24 h-24 mx-auto mb-2"></div>
                <div class="font-bold" :style="{ color: settings.secondary_color }">
                  Nom Orateur
                </div>
                <div class="text-gray-600">Titre Orateur</div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <h2 class="text-xl mb-2">Programme</h2>
            <div class="space-y-4">
              <div class="bg-gray-300 h-6 rounded"></div>
              <div class="bg-gray-300 h-6 rounded"></div>
              <div class="bg-gray-300 h-6 rounded"></div>
              <div class="bg-gray-300 h-6 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GenericButton type="clear" @click.prevent="nextStep">
    {{ $t('pagination.next') }}
  </GenericButton>
</template>

<script>
  import { ref, watchEffect } from 'vue'
  import { useStore } from 'vuex'
  import defaultLogo from '@/assets/logo.svg'
  import Facebook from '@/components/icons/Facebook.vue'
  import Twitter from '@/components/icons/Twitter.vue'
  import Instagram from '@/components/icons/Instagram.vue'
  import GenericButton from '@/components/buttons/GenericButton.vue'

  export default {
    components: { GenericButton, Facebook, Twitter, Instagram },

    setup(props, { emit }) {
      const store = useStore()
      if (!store) {
        throw new Error('Could not access Vuex store.')
      }

      const settings = ref({
        primary_color: '#fefefe',
        secondary_color: '#fefefe',
        accent_color: '#fefefe',
        logo_url: null,
        cover_image: null
      })

      const logoUrl = ref('')
      const coverUrl = ref('')
      const defaultLogoUrl = defaultLogo

      // Récupération des valeurs initiales depuis Vuex
      const localEvent = store.state.events.newEvent
      if (localEvent.branding) {
        settings.value.primary_color = localEvent.branding.primary_color
        settings.value.secondary_color = localEvent.branding.secondary_color
        settings.value.accent_color = localEvent.branding.accent_color
        settings.value.logo_url = localEvent.branding.logo_url
        settings.value.cover_image = localEvent.branding.cover_image
      }

      watchEffect(() => {
        if (localEvent.branding) {
          localEvent.branding.primary_color = settings.value.primary_color
          localEvent.branding.secondary_color = settings.value.secondary_color
          localEvent.branding.accent_color = settings.value.accent_color
          localEvent.branding.logo_url = settings.value.logo_url
          localEvent.branding.cover_image = settings.value.cover_image
        } else {
          localEvent.branding = {
            primary_color: settings.value.primary_color,
            secondary_color: settings.value.secondary_color,
            accent_color: settings.value.accent_color,
            logo_url: settings.value.logo_url,
            cover_image: settings.value.cover_image
          }
        }

        store.dispatch('events/setEvent', localEvent)
      })

      const submitForm = () => {
        console.log('Form submitted with settings:', settings.value)
      }

      const nextStep = () => {
        emit('goToStep', 3)
      }

      const onFileChange = (type, event) => {
        const fileInput = event.target
        const file = fileInput.files[0]
        const reader = new FileReader()

        reader.onload = e => {
          if (type === 'logo_url') {
            logoUrl.value = e.target.result
            settings.value.logo_url = e.target.result
          } else if (type === 'cover_image') {
            coverUrl.value = e.target.result
            settings.value.cover_image = e.target.result
          }
        }

        if (file) {
          reader.readAsDataURL(file)
        }
      }

      return {
        settings,
        logoUrl,
        coverUrl,
        defaultLogoUrl,
        localEvent,
        nextStep,
        submitForm,
        onFileChange
      }
    }
  }
</script>

<style>
  .jumbotron {
    height: 200px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>
