<template>
  <div class="p-4 mx-auto" v-if="loading">
    <CardSkeleton />
  </div>

  <Suspense>
    <TransitionGroup
      name="list"
      tag="ul"
      class="relative z-0 divide-y divide-gray-200 border-b border-gray-200"
    >
      <li
        v-for="event in events"
        :key="event.id"
        class="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
      >
        <router-link
          :to="'/events/' + event.id"
          class="flex items-center justify-between space-x-4"
        >
          <!-- Repo name and link -->
          <div class="min-w-0 space-y-3">
            <div class="flex items-center space-x-3">
              <span
                :class="[
                  !event.is_disabled ? 'bg-mantis-100' : 'bg-gray-100',
                  'h-4 w-4 rounded-full flex items-center justify-center'
                ]"
                aria-hidden="true"
              >
                <span
                  :class="[
                    !event.is_disabled ? 'bg-mantis-400' : 'bg-gray-400',
                    'h-2 w-2 rounded-full'
                  ]"
                />
              </span>

              <span class="block">
                <h2 class="text-sm font-medium">
                  <a :href="event.href">
                    <span class="absolute inset-0" aria-hidden="true" />
                    {{ event.name }}
                    <span class="sr-only">{{
                      !event.is_disabled ? 'Running' : 'Not running'
                    }}</span>
                  </a>
                </h2>
              </span>
            </div>
            <router-link
              :to="'/events/' + event.id"
              class="relative group flex items-center space-x-2.5"
            >
              <span class="text-xs text-gray-500 group-hover:text-gray-900 font-medium">
                Du {{ $moment(event.start_date).locale('fr').format('D MMMM YYYY à HH:mm') }} au
                {{ $moment(event.end_date).format('D MMMM YYYY à HH:mm') }}
              </span>
            </router-link>
          </div>
          <div class="sm:hidden">
            <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <!-- Repo meta info -->
          <div class="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
            <p class="flex items-center space-x-4">
              <a
                :href="event.siteHref"
                class="relative text-sm text-gray-500 hover:text-gray-900 font-medium"
              >
                Lien d'inscription
              </a>
              <button
                type="button"
                class="relative bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <span class="sr-only">{{
                  event.has_webinar ? 'Add to favorites' : 'Remove from favorites'
                }}</span>
                <StarIcon
                  @click.prevent="starEvent(event)"
                  :class="[
                    true
                      ? 'text-yellow-300 hover:text-yellow-400'
                      : 'text-gray-300 hover:text-gray-400',
                    'h-5 w-5'
                  ]"
                  aria-hidden="true"
                />
              </button>
            </p>
            <p class="flex text-gray-500 text-sm space-x-2">
              <span class="capitalize">{{ event.type }}</span>
              <span aria-hidden="true">&middot;</span>
              <span> {{ event.has_webinar }}</span>
              <span aria-hidden="true">&middot;</span>
              <span>{{ event.venue }}</span>
            </p>
          </div>
        </router-link>
      </li>
      <div v-if="!events.length && !loading" class="p-5">
        <EventTypeSelector />
      </div>
    </TransitionGroup>
  </Suspense>
</template>

<script>
  import http from '@/http/index.js'
  import EventTypeSelector from '@/components/events/EventTypeSelector.vue'
  import { ChevronRightIcon, StarIcon, PlusIcon } from '@heroicons/vue/solid'
  import CardSkeleton from '@/components/skeletons/CardSkeleton.vue'
  import {
    CalendarIcon,
    ClockIcon,
    PhotographIcon,
    TableIcon,
    ViewBoardsIcon,
    ViewListIcon
  } from '@heroicons/vue/outline'

  export default {
    name: 'ListEvents',
    components: {
      ChevronRightIcon,
      StarIcon,
      PlusIcon,
      CalendarIcon,
      ClockIcon,
      PhotographIcon,
      TableIcon,
      ViewBoardsIcon,
      ViewListIcon,
      EventTypeSelector,
      CardSkeleton
    },
    data() {
      return {
        account: this.$route.params.slug,
        events: [],
        loading: true,
        items: [
          {
            title: 'Créer un symposium',
            description:
              'Organisez un symposium pour discuter des sujets de pointe avec des experts.',
            icon: ViewListIcon,
            background: 'bg-pink-500',
            href: '/events/create'
          },
          {
            title: 'Créer un congrès',
            description: 'Planifiez un congrès pour réunir les professionnels de votre domaine.',
            icon: CalendarIcon,
            background: 'bg-yellow-500',
            href: '/events/create'
          },
          {
            title: 'Créer une foire',
            description: 'Mettez en place une foire pour exposer divers produits et services.',
            icon: PhotographIcon,
            background: 'bg-green-500',
            href: '/events/create'
          },
          {
            title: 'Créer un séminaire',
            description: 'Organisez un séminaire pour des sessions éducatives et informatives.',
            icon: ViewBoardsIcon,
            background: 'bg-blue-500',
            href: '/events/create'
          },
          {
            title: 'Créer un webinar',
            description: 'Mettez en place un atelier pour des sessions pratiques et interactives.',
            icon: TableIcon,
            background: 'bg-primary-500',
            href: '/events/create'
          },
          {
            title: 'Créer une conférence',
            description:
              'Organisez une conférence pour présenter et discuter des recherches récentes.',
            icon: ClockIcon,
            background: 'bg-purple-500',
            href: '/events/create'
          }
        ]
      }
    },
    methods: {
      async fetchEvents() {
        await http
          .get(`customer/accounts/${this.account}/events`)
          .then(response => {
            this.events = response.data.data
          })
          .catch(error => {
            this.$store.dispatch('toast/showToast', {
              type: 'danger',
              title: "Vous n'avez pas accès au compte.",
              body: 'Veuillez contacter votre administrateur.'
            })
            this.$router.go(-1)
          })
          .finally(() => {
            this.loading = false
          })
      },
      async starEvent(event) {
        await console.log(event)
      }
    },
    mounted() {
      this.loading = true

      this.fetchEvents()
      // TODO : Reconfigure realtime with laravel reverb
      // Echo.channel(`customers.${this.$store.state.auth.user.id}`).listen('.event.created', e => {
      //   this.fetchEvents()
      // })
    }
  }
</script>
