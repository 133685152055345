<template>
  <div class="flex-grow w-full lg:flex">
    <div class="flex-1 min-w-0 bg-white xl:flex">
      <div :class="{
        'xl:border-gray-200 xl:border-r': $store.state.appSettings.direction === 'ltr',
        'xl:border-gray-200 xl:border-l': $store.state.appSettings.direction === 'rtl'
      }" class="xl:flex-shrink-0 xl:w-64 bg-white">
        <div class="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 h-full">
          <div class="flex justify-between h-full">
            <div class="flex-1 space-y-8 flex flex-col justify-between h-full">
              <!-- Top Navigation -->
              <nav class="space-y-1" aria-label="Sidebar">
                <a v-for="item in navigation" :key="item.name" href="#" @click="goToTab(item)" :class="[
        item.current
          ? 'bg-gray-200 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'flex items-center px-3 py-2 text-sm font-medium rounded-md gap-1'
      ]" :aria-current="item.current ? 'page' : undefined">
                  <component :is="item.icon" :class="[
        item.current ? 'text-gray-500' : 'text-gray-400',
        'flex-shrink-0 h-6 w-6'
      ]" aria-hidden="true" />
                  <span class="truncate">
                    {{ item.name }}
                  </span>
                  <span v-if="item.count" :class="[
        item.current ? 'bg-gray-50' : 'bg-gray-200 text-gray-600',
        'ml-auto inline-block py-0.5 px-3 text-xs rounded-full',
        item.class ? item.class : null
      ]">
                    {{ item.count }}
                  </span>
                </a>
              </nav>

              <!-- Bottom Navigation -->
              <nav class="mt-auto space-y-1" aria-label="Bottom Sidebar">
                <a v-for="item in bottomNavigation" :key="item.name" href="#" @click="goToBottomTab(item)" :class="[
        item.current
          ? 'bg-gray-200 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'flex items-center px-3 py-2 text-sm font-medium rounded-md'
      ]" :aria-current="item.current ? 'page' : undefined">
                  <component :is="item.icon" :class="[
        item.current ? 'text-gray-500' : 'text-gray-400',
        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
      ]" aria-hidden="true" />
                  <span class="truncate">
                    {{ item.name }}
                  </span>
                  <span v-if="item.count" :class="[
        item.current ? 'bg-gray-50' : 'bg-gray-200 text-gray-600',
        'ml-auto inline-block py-0.5 px-3 text-xs rounded-full',
        item.class ? item.class : null
      ]">
                    {{ item.count }}
                  </span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex-1 relative z-0 flex overflow-hidde transform transition ease-in-out duration-500 sm:duration-700">
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
          <!-- Breadcrumb -->
          <nav class="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
            <a href="#" class="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
              <ChevronLeftIcon class="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Inscrits</span>
            </a>
          </nav>

          <article>
            <!-- Profile header -->
            <div>
              <!--              <div class='group relative'>-->
              <!--                <img-->
              <!--                    class='h-32 w-full object-cover lg:h-48'-->
              <!--                    :src='event.cover_image_url ? event.cover_image_url : defaultCoverImage'-->
              <!--                    alt=''-->
              <!--                />-->
              <!--                <div-->
              <!--                    class='absolute top-0 left-0 cursor-pointer w-full h-full flex flex-col justify-center items-center bg-[#7c7c7c99] opacity-0 group-hover:opacity-100 duration-500'-->
              <!--                >-->
              <!--                  <button-->
              <!--                      class='mt-5 px-8 py-3 rounded-full hover:bg-secondary-700 duration-300 text-white flex font-bold justify-between gap-2 items-center'-->
              <!--                      @click='changeCoverPicture'-->
              <!--                  >-->
              <!--                    <svg-->
              <!--                        xmlns='http://www.w3.org/2000/svg'-->
              <!--                        fill='none'-->
              <!--                        viewBox='0 0 24 24'-->
              <!--                        stroke-width='1.5'-->
              <!--                        stroke='currentColor'-->
              <!--                        class='w-6 h-6'-->
              <!--                    >-->
              <!--                      <path-->
              <!--                          stroke-linecap='round'-->
              <!--                          stroke-linejoin='round'-->
              <!--                          d='M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3'-->
              <!--                      />-->
              <!--                    </svg>-->

              <!--                    Changer la photo de couverture-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="px-4 sm:px-6 lg:px-8">
                <div class="sm:flex sm:items-end sm:space-x-5">
                  <!--                <div class='-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5'>-->

                  <div class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                    <div class="sm:hidden 2xl:flex min-w-0 flex-1 gap-2 flex-col">
                      <div class="animate-pulse flex space-x-4" v-if="loading">
                        <div class="flex-1 py-1">
                          <div class="h-4 bg-slate-700 rounded"></div>
                        </div>
                      </div>
                      <h1 class="text-2xl font-bold text-gray-900 truncate" v-else>
                        {{ event.name }}
                      </h1>
                      <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-1" v-if="!loading">
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 gap-1.5">
                          <i class="fal fa-people-carry"></i>
                          {{ event.has_webinar ? 'Hybride' : 'Présentiel' }}
                        </span>
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 gap-1.5">
                          <i class="fal fa-map-marker"></i>
                          {{ event.venue }}
                        </span>
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 gap-1.5">
                          <i class="fal fa-people"></i>
                          {{ $t('events.types.' + event.type + '.title') }}
                        </span>
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 gap-1.5">
                          <i class="fal fa-calendar-check"></i>
                          {{ $t('words.ends') }} {{ $moment(event.end_date).fromNow() }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-1 gap-2">
                      <GenericButton type="outline-primary" @click="showStudio" icon="fas fa-wand-magic-sparkles">
                        {{ $t('general.controls.studio') }}
                      </GenericButton>
                      <GenericButton type="clear" @click="showEditEventModal = true" icon="fas fa-pencil">
                        {{ $t('general.controls.edit') }}
                      </GenericButton>
                      <GenericButton type="outline-danger" @click="showRemoveEventModal = true" icon="fas fa-trash">
                        {{ $t('general.controls.delete') }}
                      </GenericButton>

                    </div>
                  </div>
                </div>
                <div class="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                  <h1 class="text-2xl font-bold text-gray-900 truncate">
                    {{ event.name }}
                  </h1>
                </div>
              </div>
            </div>

            <!-- Tabs -->
            <div class="mt-6 sm:mt-2 2xl:mt-5">
              <div class="border-b border-gray-200">
                <div class="px-4 sm:px-6 lg:px-8">
                  <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[
        tab.current
          ? 'border-secondary-500 text-gray-900'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
      ]" :aria-current="tab.current ? 'page' : undefined">
                      {{ tab.name }}
                    </a>
                    <a :class="[
        event.has_webinar
          ? 'border-secondary-500 text-gray-900'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
      ]" :aria-current="event.has_webinar ? 'page' : undefined">
                      {{ activeTab.name }}
                    </a>
                  </nav>
                </div>
              </div>
            </div>

            <div class="mt-6 px-4 sm:px-6 lg:px-8">
              <component :is="activeTab.component" :dataProp="event"></component>
            </div>

            <!-- Team member list -->
            <div class="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8"></div>
          </article>
        </main>
      </div>

      <TransitionRoot as="template" :show="showChangeCoverPictureModal">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="showChangeCoverPictureModal = false">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
              leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button"
                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                    @click="showChangeCoverPictureModal = false">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start flex-col items-center">
                  <div
                    class="mx-auto flex-shrink-0 flex items-center justify-center self-center h-12 w-12 rounded-full bg-mantis-100 sm:mx-0 sm:h-10 sm:w-10">
                    <InformationCircleIcon class="h-6 w-6 text-mantis-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 sm:mt-0 sm:ml-4">
                    <DialogTitle as="h3" class="text-lg leading-6 text-center font-medium text-gray-900">
                      Changer la photo de couverture
                    </DialogTitle>
                    <div class="mt-2">
                      <div class="bg-secondary-200 rounded border-secondary-300 my-4 p-3">
                        <ul class="text-sm">
                          <li>
                            La photo de couverture de votre événement doit être choisie avec soin :
                            elle reflète l'atmosphère de celui-ci .
                          </li>
                          <li>
                            Les dimensions optimales pour la photo de couverture sont
                            <strong>1920 x 250</strong>
                          </li>
                          <li>Les formats acceptés sont : <strong>PNG, GIF et JPEG</strong></li>
                          <li>La taille maximale acceptée est de <strong>10 MO</strong></li>
                        </ul>
                      </div>

                      <div
                        class="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md mt-4">
                        <div class="space-y-1">
                          <svg class="mx-auto text-center h-12 w-12 text-gray-400" stroke="currentColor" fill="none"
                            viewBox="0 0 48 48" aria-hidden="true">
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <div class="flex text-sm text-gray-600">
                            <label for="file-upload"
                              class="relative cursor-pointer bg-white rounded-md font-medium text-secondary-600 hover:text-secondary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary-500">
                              <span>Envoyer un fichier</span>
                              <input id="file-upload" name="file-upload" type="file" class="sr-only" ref="file"
                                @change="onChangeFileUpload()" />
                            </label>
                            <p class="pl-1">ou glissez-déposer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary-600 text-base font-medium text-white hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 sm:ml-3 sm:w-auto sm:text-sm"
                    @click="updateEvent">
                    Envoyer
                  </button>
                  <button type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="showChangeCoverPictureModal = false">
                    Annuler
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <TransitionRoot as="template" :show="showEditEventModal">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="showEditEventModal = false">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
              leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button"
                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                    @click="showEditEventModal = false">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start flex-col items-center">
                  <div class="mt-3 sm:mt-0 w-full">
                    <DialogTitle as="h3" class="text-lg leading-6 text-center font-medium text-gray-900">
                      {{ $t('general.controls.edit') }}
                    </DialogTitle>
                    <DialogPanel class="flex flex-col gap-2">
                      <div class="rounded-md bg-yellow-50 p-4" v-if="showEventDateChangeAlert">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-black text-yellow-800">Attention</h3>
                            <div class="mt-2 text-sm text-yellow-700">
                              <p>{{ $t('general.hints.change_event_date_disclaimer') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="event.name" class="block text-sm font-medium text-gray-700">
                          {{ $t('labels.title') }}
                        </label>
                        <div class="mt-1">
                          <input id="event.name" name="event.name" v-model="event.name" type="text"
                            autocomplete="event.name" required
                            class="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm" />
                        </div>
                      </div>
                      <div>
                        <label for="event.description" class="block text-sm font-medium text-gray-700">
                          {{ $t('labels.description') }}
                        </label>
                        <div class="mt-1">
                          <ckeditor :editor="editor" v-model="event.description"
                            class="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm">
                          </ckeditor>
                        </div>
                      </div>

                      <div class="flex justify-between gap-2">
                        <div class="w-1/2">
                          <label for="event.start_date" class="block text-sm font-medium text-gray-700">
                            {{ $t('labels.date_start') }}
                          </label>
                          <div class="mt-1">
                            <input @change="showEventDateChangeAlert = true" id="event.start_date"
                              name="event.start_date" v-model="event.start_date" type="datetime-local"
                              autocomplete="event.start_date" required
                              class="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm" />
                          </div>
                        </div>
                        <div class="w-1/2">
                          <label for="event.end_date" class="block text-sm font-medium text-gray-700">
                            {{ $t('labels.date_end') }}
                          </label>
                          <div class="mt-1">
                            <input id="event.end_date" name="event.end_date" v-model="event.end_date"
                              type="datetime-local" autocomplete="event.end_date" required
                              class="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm" />
                          </div>
                        </div>
                      </div>
                    </DialogPanel>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-1">
                  <GenericButton @click="updateEvent">
                    {{ $t('general.controls.update') }}
                  </GenericButton>
                  <GenericButton type="clear" @click="showChangeCoverPictureModal = false">
                    {{ $t('general.controls.cancel') }}
                  </GenericButton>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <DeleteModal :is-open="showRemoveEventModal" :label="event.name" :text="deleteModalText"
        @action="processDeleteModalAction" />
    </div>
  </div>
</template>

<script>
import http from '../../http/index.js'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import { markRaw } from 'vue'
import defaultCoverImage from '@/assets/cover_stock.png'

import {
  CalendarIcon,
  ChartBarIcon,
  InboxIcon,
  UsersIcon,
  SearchIcon,
  FilterIcon,
  ChevronLeftIcon,
  TrashIcon,
  PencilIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
  CogIcon,
  BriefcaseIcon,
  LocationMarkerIcon,
  CurrencyDollarIcon
} from '@heroicons/vue/outline'
import {
  Dialog,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import Attendees from '@/pages/events/tabs/Attendees.vue'
import MetaInfo from '@/pages/events/tabs/MetaInfo.vue'
import { mapActions, mapMutations, useStore } from 'vuex'
import Activities from '@/pages/events/tabs/Activities.vue'
import { useI18n } from 'vue-i18n'
import DeleteModal from '@/components/modals/DeleteModal.vue'
import GenericButton from '@/components/buttons/GenericButton.vue'
import Scheduling from '@/pages/events/tabs/Scheduling.vue'
import Reports from '@/pages/events/tabs/Reports.vue'
import Studio from '@/components/events/Studio.vue'

const bottomNavigation = [
  {
    name: 'Configuration',
    tab: '/settings',
    icon: CogIcon,
    current: false,
    component: markRaw(MetaInfo)
  }
]

export default {
  name: 'ShowEvent.vue',
  components: {
    DialogPanel,
    SearchIcon,
    FilterIcon,
    ChevronLeftIcon,
    GenericButton,
    TrashIcon,
    Studio,
    PencilIcon,
    InformationCircleIcon,
    XIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    defaultCoverImage,
    ExclamationIcon,
    ckeditor: CKEditor.component,
    DeleteModal,
    BriefcaseIcon,
    LocationMarkerIcon,
    CurrencyDollarIcon,
    CalendarIcon
  },
  data() {
    return {
      event: {},
      showChangeCoverPictureModal: false,
      showEditEventModal: false,
      showRemoveEventModal: false,
      showEventDateChangeAlert: false,
      activeTab: this.navigation[0],
      tabs: [],
      file: '',
      loading: false,
      defaultCoverImage: defaultCoverImage,
      editor: ClassicEditor
    }
  },
  mounted() {
    this.fetchEvent()
  },
  setup() {
    const { t } = useI18n()
    const store = useStore();

    const navigation = [
      {
        name: t('events.menu.file'),
        tab: '/',
        icon: InformationCircleIcon,
        current: true,
        component: markRaw(MetaInfo)
      },
      {
        name: t('events.menu.attendees'),
        tab: 'inscriptions',
        icon: UsersIcon,
        current: false,
        component: markRaw(Attendees)
      },
      {
        name: t('events.menu.activities'),
        tab: 'poll',
        icon: InboxIcon,
        current: false,
        component: markRaw(Activities)
      },
      // {
      //   name: t('events.menu.scheduling'),
      //   tab: 'streaming',
      //   icon: SignalIcon,
      //   current: false,
      //   count: 'PRO',
      //   class: 'bg-gold-500 text-black'
      // },
      {
        name: t('events.menu.scheduling'),
        tab: 'streaming',
        icon: CalendarIcon,
        current: false,
        class: 'bg-gold-500 text-black',
        component: markRaw(Scheduling)
      },
      // {
      //   name: 'VR',
      //   tab: 'vr',
      //   icon: VrIcon,
      //   current: false,
      //   count: 'dev.',
      //   class: 'bg-gold-500 text-black'
      // },
      {
        name: t('events.menu.reports'),
        tab: 'stats',
        icon: ChartBarIcon,
        current: false,
        component: markRaw(Reports)
      }
    ]

    const showStudio = () => {
      store.commit('drawer/setDynamicComponent', Studio)
      store.commit('drawer/setDrawerTitle', t('general.controls.studio'))
      store.commit('drawer/setDrawerDescription', t('general.hints.studio'))
      store.commit('drawer/openDrawer')
    }

    const deleteModalText = t('general.delete_confirmation', {
      entity: t('entities.event').toLowerCase()
    })

    return {
      navigation,
      deleteModalText,
      bottomNavigation,
      showStudio
    }
  },
  methods: {
    ...mapMutations('drawer', ['CLOSE_DRAWER']),
    ...mapActions('drawer', ['setDynamicComponent', 'openDrawer']),
    fetchEvent() {
      this.loading = true
      http
        .get('customer/events/' + this.$route.params.id)
        .then(response => {
          this.event = response.data.data
          this.$store.commit('accounts/SET_ACTIVE_EVENT', this.event)
          const item = this.navigation.find(item => item.tab === 'inscriptions')
          if (item) {
            item.count = response.data.data.stats?.attendees_count
          }
        })
        .catch(errors => {
          this.$router.go(-1)
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeCoverPicture() {
      this.showChangeCoverPictureModal = true
    },
    updateCoverImage() {
      let formData = new FormData()
      let options = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      formData.append('file', this.file)

      http
        .post('customer/events/' + this.$route.params.id + '/change-cover', formData, options)
        .then(data => {
          this.fetchEvent()
          this.showChangeCoverPictureModal = false
        })
        .catch(function () {
          console.log('FAILURE!!')
        })
    },
    updateEvent() {
      http
        .patch('customer/events/' + this.$route.params.id, this.event)
        .then(data => {
          console.log(data)
          return
          // this.fetchEvent()
          // this.showEditEventModal = false
        })
        .catch(function () {
          console.log('FAILURE!!')
        })
    },
    goToTab(tab) {
      this.navigation.forEach(item => {
        item.current = item.tab === tab.tab
      })
      this.activeTab = tab
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0]
    },
    processDeleteModalAction(action) {
      if (action !== null) {
        http
          .delete('customer/events/' + this.event.id)
          .then(response => {
            this.$router.go(-1)
          })
          .catch(error => {
            console.log(error.response.data)
          })
      }
      this.showRemoveEventModal = false
    },
  }
}
</script>

<style scoped></style>
