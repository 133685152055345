<template>
  <div class="rounded-md bg-mantis-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <XCircleIcon class="h-5 w-5 text-mantis-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-mantis-800">{{ message }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
  import { XCircleIcon } from '@heroicons/vue/solid'

  export default {
    name: 'SuccessComponent',
    props: ['message', 'classes'],
    components: { XCircleIcon }
  }
</script>
