<template>
    <div class="loading-text">
      Chargement
      <span class="dots">
        <span>.</span><span>.</span><span>.</span>
      </span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingText'
  }
  </script>
  
  <style scoped>
  .loading-text {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    gap: 5;
    color: white;
  }
  
  .dots span {
    display: inline-block;
    animation: blink 1.4s infinite both;
  }
  
  .dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dots span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  </style>
  