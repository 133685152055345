<template>
  <div class="animate-pulse space-y-4">
    <!-- Header du tableau -->
    <div class="h-4 bg-gray-400 rounded"></div>
    <!-- Lignes du tableau -->
    <div class="space-y-2">
      <div class="h-2 bg-gray-400 rounded"></div>
      <div class="h-2 bg-gray-400 rounded"></div>
      <div class="h-2 bg-gray-400 rounded"></div>
      <div class="h-2 bg-gray-400 rounded"></div>
      <div class="h-2 bg-gray-400 rounded"></div>
    </div>
  </div>
</template>
