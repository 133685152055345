export default {
  namespaced: true,
  state: {
    notifications: []
  },
  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    }
  },
  actions: {
    setNotifications({ commit }, notifications) {
      commit('SET_NOTIFICATIONS', notifications)
    },
    resetNotifications({ commit }) {
      commit('SET_NOTIFICATIONS', [])
    }
  }
}
