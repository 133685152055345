<template>
    <svg version="1.1" id="emailsIcon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" :class="svgClass" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
        <path opacity="1.000000" stroke="none" d="
M1.000000,474.000000 
	C1.000000,386.645782 1.000000,299.291534 1.295872,211.315186 
	C21.096909,194.509735 40.534504,178.227829 60.846622,163.067703 
	C68.108009,157.648117 71.013855,151.661911 70.888214,142.287994 
	C70.310783,99.208832 70.697289,56.117367 70.546486,13.030984 
	C70.529739,8.247166 71.374786,4.222815 75.000000,1.000000 
	C119.020897,1.000000 163.041794,1.000000 207.790680,1.234476 
	C213.072647,5.036989 215.039978,10.702166 212.964035,15.509042 
	C210.941635,20.191977 207.075790,21.493105 202.032852,21.468861 
	C167.872833,21.304632 133.711639,21.379646 99.550835,21.381744 
	C91.389748,21.382246 91.378044,21.389952 91.377754,29.289782 
	C91.374825,111.109123 91.408646,192.928513 91.288719,274.747681 
	C91.282051,279.295441 92.659698,282.527679 95.861938,285.698151 
	C119.538635,309.140137 143.025681,332.773529 166.654266,356.264343 
	C175.892975,365.449188 172.214157,364.759827 182.238739,356.231781 
	C199.928268,341.183167 219.586380,331.164581 242.865051,328.446686 
	C246.957657,327.968903 251.284622,328.214081 255.343048,327.034058 
	C254.915268,324.615265 253.349472,323.740112 252.216476,322.589294 
	C245.202255,315.464844 238.129181,308.398315 231.075470,301.312744 
	C225.934219,296.148254 225.486053,295.923126 220.286758,300.931702 
	C200.670044,319.828857 165.326721,319.741119 144.467453,298.427307 
	C125.376518,278.920410 124.342506,246.711197 140.180466,226.543396 
	C148.430359,216.038101 158.291229,206.952911 168.131210,198.039536 
	C178.066940,189.039474 184.336929,178.212448 189.413742,166.099579 
	C208.551193,120.439278 228.019379,74.917564 247.377945,29.349998 
	C251.839203,18.848745 260.376526,17.165222 268.509155,25.257271 
	C282.329224,39.008301 296.140594,52.769585 309.795227,66.683960 
	C312.517548,69.458046 314.729950,70.190697 318.447113,68.428284 
	C330.290680,62.812851 342.582703,63.242493 354.109619,68.725632 
	C376.959656,79.594933 384.318756,104.214516 375.258789,126.419937 
	C374.010895,129.478439 374.477631,131.237198 376.643616,133.386108 
	C390.483612,147.117035 404.221802,160.950516 418.034698,174.708893 
	C419.123657,175.793564 419.974670,177.518494 422.638123,177.014511 
	C422.638123,166.944748 422.608154,156.829025 422.648468,146.713593 
	C422.676178,139.760635 426.011047,136.613770 433.179108,136.634521 
	C439.914307,136.654022 443.279785,139.935349 443.367859,146.591339 
	C443.396545,148.757309 443.458801,150.927078 443.367371,153.089249 
	C443.266479,155.474670 444.228790,157.295380 446.048096,158.703827 
	C458.514954,168.355164 471.122986,177.829803 483.426056,187.685257 
	C493.384033,195.662125 504.400970,202.368668 513.000000,212.000000 
	C513.000000,298.687561 513.000000,385.375122 512.999512,472.899506 
	C509.803925,484.209015 504.589874,493.323334 496.407990,500.874268 
	C490.132629,506.665741 482.451050,509.525879 475.000000,513.000000 
	C329.979095,513.000000 184.958206,513.000000 39.250549,512.810669 
	C29.531101,509.144348 21.135559,504.887207 14.238310,497.662109 
	C7.690718,490.803345 4.179786,482.571899 1.000000,474.000000 
M148.500168,463.399933 
	C136.506226,463.397339 124.475990,463.971222 112.529213,463.219177 
	C101.997322,462.556213 93.102760,464.343811 86.703079,473.416016 
	C86.152664,474.196320 85.131653,474.629059 84.396103,475.296936 
	C78.560272,480.595459 72.740623,485.911774 65.843918,492.200195 
	C193.704803,492.200195 319.970856,492.200195 446.369537,492.200195 
	C446.114075,489.453125 444.136139,488.248962 442.610474,486.880707 
	C433.966339,479.128113 425.160217,471.555664 416.538239,463.778870 
	C401.741882,450.432983 386.910919,437.120911 372.332855,423.538788 
	C354.591736,407.009705 336.450958,390.926178 318.537964,374.590637 
	C303.999359,361.332367 287.563293,352.671631 267.843414,350.166229 
	C247.769714,347.615845 229.296677,351.738129 212.035477,361.975769 
	C201.370911,368.300934 192.699768,377.037292 183.621414,385.324249 
	C164.257736,403.000092 144.647888,420.406097 125.192368,437.981842 
	C124.086670,438.980743 122.221725,439.751984 122.724564,442.072662 
	C124.388885,442.969238 126.250565,442.593719 128.022995,442.598541 
	C142.015961,442.636841 156.009293,442.578644 170.002014,442.649261 
	C176.646149,442.682800 180.324036,446.370850 180.362946,452.740814 
	C180.404205,459.497833 176.785904,463.275665 169.989761,463.364075 
	C163.160904,463.452911 156.330124,463.394165 148.500168,463.399933 
M120.146416,342.353912 
	C87.716217,309.903931 55.286022,277.453918 22.699314,244.847321 
	C21.088530,246.699127 21.396122,248.081573 21.395849,249.350204 
	C21.380913,318.666534 21.296921,387.983093 21.475914,457.298981 
	C21.502623,467.641724 24.154261,477.329529 32.683609,484.507812 
	C35.890224,487.206482 38.419079,488.159576 41.470341,484.477936 
	C43.374454,482.180450 45.605694,480.132660 47.824413,478.120880 
	C60.987560,466.185547 74.209023,454.314545 87.372284,442.379303 
	C95.864700,434.679169 104.261406,426.873505 112.753998,419.173523 
	C125.915245,407.240540 139.043762,395.269196 152.356827,383.507263 
	C156.315781,380.009583 155.658615,377.646362 152.153748,374.260620 
	C141.489716,363.959045 131.126877,353.345734 120.146416,342.353912 
M368.315735,389.164368 
	C379.758484,399.533600 391.237885,409.862823 402.636322,420.280487 
	C426.054016,441.683075 449.481934,463.075226 472.758087,484.631012 
	C475.951538,487.588470 478.070648,487.362183 481.199280,484.710388 
	C490.001617,477.249695 492.513367,467.195923 492.538452,456.517517 
	C492.700989,387.377502 492.618835,318.236938 492.575958,249.096542 
	C492.575134,247.736237 493.226440,246.123398 491.599182,244.833282 
	C490.722504,245.629242 489.841888,246.363861 489.034241,247.171448 
	C448.154968,288.046173 407.187256,328.833221 366.473389,369.872131 
	C356.003967,380.425140 354.685913,376.824310 368.315735,389.164368 
M409.143188,294.632019 
	C412.207581,291.571869 415.130005,288.350220 418.376068,285.496765 
	C421.653961,282.615295 422.741943,279.289459 422.711609,274.938843 
	C422.536041,249.787338 422.645996,224.633942 422.586273,199.481339 
	C422.581421,197.442245 423.394012,195.114136 421.433868,193.440781 
	C371.979553,214.536926 322.605713,235.598740 273.236847,256.658447 
	C274.064240,259.231842 275.759674,260.397736 277.148712,261.794556 
	C285.722778,270.416382 294.346436,278.989227 302.887177,287.643860 
	C309.316956,294.159393 309.320892,300.703827 302.899475,307.260132 
	C296.955627,313.328827 290.872284,319.260864 284.929321,325.330414 
	C283.746338,326.538605 281.734314,327.378723 282.265472,330.095032 
	C285.437012,331.028595 288.766205,331.919250 292.037750,332.986542 
	C308.939514,338.500275 323.313690,348.259094 336.288513,360.101044 
	C339.839172,363.341644 341.623291,362.194916 344.341248,359.460663 
	C365.713226,337.960602 387.186188,316.560944 409.143188,294.632019 
M252.376572,237.091904 
	C254.722031,240.189346 257.555237,239.311966 260.430298,238.078247 
	C289.420532,225.638229 318.408142,213.192078 347.407288,200.772827 
	C348.953766,200.110519 350.574127,199.649872 350.794037,197.024063 
	C315.853394,162.486435 281.716827,126.661728 245.479736,93.146049 
	C243.562164,94.435150 243.173645,95.936073 242.595749,97.291000 
	C230.424179,125.827507 218.360260,154.410614 206.006241,182.867798 
	C204.258896,186.892776 204.608200,189.408768 207.713882,192.465561 
	C222.545074,207.063354 237.173218,221.867447 252.376572,237.091904 
M197.979706,289.475006 
	C200.662766,288.146576 203.214188,286.708099 205.378372,284.514465 
	C214.611145,275.156097 223.855927,265.804626 233.317368,256.679810 
	C236.158417,253.939835 236.245468,252.235443 233.392426,249.440460 
	C220.197601,236.514114 207.139374,223.446274 194.174225,210.289169 
	C191.502945,207.578323 189.673874,207.227219 186.863586,210.141479 
	C178.547394,218.765305 169.808609,226.984344 161.563797,235.673553 
	C153.492432,244.179977 149.456985,254.250061 151.813187,266.182129 
	C155.578796,285.251404 177.529587,299.163239 197.979706,289.475006 
M374.161957,162.338013 
	C337.263458,125.441376 300.364990,88.544746 263.375397,51.557018 
	C259.405975,55.375000 258.959290,59.947243 256.858856,63.656322 
	C254.648834,67.558945 255.675629,70.584213 258.904144,73.742912 
	C272.587982,87.130730 286.030029,100.765579 299.569244,114.301323 
	C323.115753,137.841797 346.660248,161.384277 370.237976,184.893448 
	C371.839844,186.490662 373.455109,189.234039 375.909058,188.337341 
	C381.378326,186.338837 387.038879,184.568619 393.021149,180.840179 
	C386.480865,174.426254 380.572021,168.631546 374.161957,162.338013 
M40.268921,207.854355 
	C37.525341,209.994339 34.800392,212.158768 32.033066,214.267593 
	C29.129976,216.479889 28.465204,218.389481 31.547586,221.392044 
	C43.349258,232.888016 54.895966,244.645584 66.557838,256.285461 
	C67.457558,257.183502 68.179680,258.445953 69.981972,258.362976 
	C69.981972,233.981400 69.981972,209.621811 69.981972,184.021606 
	C59.586990,192.334991 50.189594,199.850555 40.268921,207.854355 
M443.376251,207.499969 
	C443.376251,224.175751 443.376251,240.851547 443.376251,257.117645 
	C445.683655,257.876038 446.473846,257.216827 447.178772,256.514435 
	C459.193451,244.542648 471.194763,232.557480 483.198212,220.574402 
	C485.411255,218.365112 484.871490,217.032593 482.355896,215.132172 
	C473.499207,208.441406 465.045410,201.215607 456.159332,194.566223 
	C452.337280,191.706192 448.996399,187.933060 443.376526,185.803253 
	C443.376526,193.306961 443.376526,199.903519 443.376251,207.499969 
M271.333191,307.134430 
	C273.380859,305.184021 275.315552,303.094696 277.502747,301.315887 
	C280.750153,298.674927 280.580231,296.484528 277.559143,293.642242 
	C271.743805,288.171112 266.264984,282.344269 260.561340,276.751953 
	C251.495117,267.862671 254.353638,268.315308 245.224640,276.887604 
	C242.600052,279.352173 242.386719,281.240723 245.100800,283.870789 
	C252.389786,290.934174 259.449921,298.233307 266.642059,305.397522 
	C267.764404,306.515472 268.750854,308.136475 271.333191,307.134430 
M356.519043,102.914886 
	C352.066833,90.909622 340.477936,83.623497 332.962738,87.429321 
	C340.781952,95.179306 348.750153,103.076935 356.738739,110.994804 
	C358.921570,109.114120 356.628204,106.325829 356.519043,102.914886 
z" />
        <path opacity="1.000000" stroke="none" d="
M437.545898,1.000000 
	C446.252380,7.160452 446.030518,14.803457 438.532379,21.827448 
	C426.136658,33.439251 414.413940,45.769241 402.399536,57.788513 
	C400.397797,59.791065 398.422363,61.921608 395.409729,62.292477 
	C391.066589,62.827126 386.911621,62.346275 384.357056,58.261055 
	C381.798370,54.169247 381.694763,49.810570 384.117615,45.543793 
	C384.768585,44.397385 385.506287,43.240631 386.433655,42.319695 
	C400.141357,28.707010 413.887787,15.133318 427.812531,1.275193 
	C431.030609,1.000000 434.061188,1.000000 437.545898,1.000000 
z" />
        <path opacity="1.000000" stroke="none" d="
M359.545898,1.000000 
	C364.958771,3.745179 366.455078,8.216087 366.381622,13.551986 
	C366.303741,19.208422 366.556885,24.876198 366.292694,30.521578 
	C365.957275,37.688797 361.341827,42.212055 355.106567,41.956520 
	C348.667542,41.692635 344.718414,37.188107 344.646667,29.894199 
	C344.589355,24.070894 344.723267,18.244978 344.615021,12.423143 
	C344.531982,7.956956 346.100281,4.335809 349.747223,1.236738 
	C353.030609,1.000000 356.061188,1.000000 359.545898,1.000000 
z" />
        <path opacity="1.000000" stroke="none" d="
M124.142235,133.388489 
	C114.085953,133.318146 109.138283,127.690941 111.636612,119.593544 
	C112.994492,115.192490 115.898148,112.633659 120.716286,112.635345 
	C137.515518,112.641251 154.314896,112.596359 171.113922,112.654182 
	C176.930801,112.674202 181.356308,117.470299 181.165100,123.272530 
	C180.979904,128.892441 176.633682,133.336578 171.026169,133.359772 
	C155.557831,133.423782 140.089050,133.386490 124.142235,133.388489 
z" />
        <path opacity="1.000000" stroke="none" d="
M172.411163,70.106903 
	C170.637833,75.290268 167.388107,77.514069 162.164734,77.447601 
	C148.685867,77.276077 135.203522,77.398270 121.722687,77.357208 
	C115.454903,77.338120 110.884453,72.943108 110.818237,66.996979 
	C110.752693,61.110283 115.369644,56.661079 121.701736,56.643467 
	C135.182571,56.605965 148.663620,56.601974 162.144455,56.636463 
	C170.000793,56.656563 174.132050,61.933594 172.411163,70.106903 
z" />
        <path opacity="1.000000" stroke="none" d="
M441.972443,95.822571 
	C438.987762,98.565460 435.804352,99.517967 432.131500,99.430428 
	C425.990997,99.284073 419.843384,99.462265 413.701111,99.360283 
	C406.783997,99.245438 401.990479,94.704163 401.976105,88.444962 
	C401.961578,82.118698 406.682953,77.732437 413.747284,77.645142 
	C419.558167,77.573326 425.370850,77.606377 431.182465,77.639832 
	C442.776947,77.706566 447.291443,85.035103 441.972443,95.822571 
z" />
        <path opacity="1.000000" stroke="none" d="
M207.790405,60.543175 
	C210.048477,65.341599 210.400772,69.759552 207.429459,73.965355 
	C204.280319,78.422859 199.537186,77.711960 195.188828,77.152718 
	C190.736526,76.580109 188.573730,72.571114 188.635010,66.413681 
	C188.686432,61.246651 191.156403,57.336727 195.409454,56.840263 
	C199.766357,56.331669 204.479218,55.600796 207.790405,60.543175 
z" />
        <path opacity="1.000000" stroke="none" d="
M221.136063,449.610504 
	C223.139252,461.710114 213.886795,465.340973 206.011673,462.750580 
	C201.533279,461.277466 199.818008,457.228699 199.815887,452.922424 
	C199.813736,448.580872 201.704254,444.564789 206.155975,443.223450 
	C210.465515,441.925018 215.068542,441.765137 218.631210,445.487000 
	C219.632324,446.532867 220.242691,447.952759 221.136063,449.610504 
z" />
    </svg>
</template>

<script>
export default {
    name: 'MyIcon',
    props: {
        svgClass: {
            type: String,
            default: ''
        }
    }
}
</script>