<template>
  <div class="rounded-md p-4 w-full mx-auto" v-if="loading">
    <TableSkeleton />
  </div>
  <Suspense>
    <div>
      <TransitionGroup name="list" tag="ul" class="divide-y divide-gray-200" v-if="accounts.length">
        <li
          v-for="account in accounts"
          :key="account.slug"
          @contextmenu.prevent="showMenu($event, account)"
        >
          <router-link
            :to="'accounts/' + account.slug + '/events'"
            class="block hover:bg-gray-50"
            @click="setActiveAccount(account)"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <Placeholder class="h-12 w-12 mr-2" bcg="fill-primary-500" />
              <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div class="truncate">
                  <div class="flex text-sm gap-1">
                    <p class="truncate font-bold">{{ account.name }}</p>
                    <p class="flex-shrink-0 font-normal text-gray-500">
                      {{ $t(`accounts.types.${account.type}.title`) }}
                    </p>
                  </div>
                  <div class="mt-2 flex">
                    <div class="flex items-center text-sm gap-1.5 text-gray-500">
                      <CalendarIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <p>
                        {{ $t('labels.created_at') }}
                        {{ ' ' }}
                        <time :datetime="account.created_at"
                          >{{ formatDate(account.created_at) }}
                        </time>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-5 flex-shrink-0">
                <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </router-link>
        </li>
      </TransitionGroup>
      <transition name="fade">
        <div
          v-if="menuVisible"
          :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
          class="absolute z-10 bg-white shadow-lg rounded-md w-64"
        >
          <ul class="py-1">
            <li>
              <button
                @click="viewItem(selectedAccount)"
                class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <i class="fal fa-eye mr-2"></i> {{ $t('general.controls.show') }}
              </button>
            </li>
            <li>
              <button
                @click="editItem(selectedAccount)"
                class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <i class="fal fa-edit mr-2"></i> {{ $t('general.controls.edit') }}
              </button>
            </li>
            <li>
              <button
                @click="deleteItem(selectedAccount)"
                class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <i class="fal fa-trash mr-2"></i>{{ $t('general.controls.delete') }}
              </button>
            </li>
            <li>
              <button
                @click="addMember(selectedAccount)"
                class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <i class="fal fa-user-plus mr-2"></i>
                {{
                  $t('general.entityAction', {
                    control: $t('general.controls.add'),
                    entity: $t('entities.user').toLowerCase()
                  })
                }}
              </button>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </Suspense>

  <div class="flex flex-col items-center" v-if="!loading && !accounts.length">
    <svg
      class="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
    <h3 class="mt-2 text-sm font-medium text-gray-900">
      {{ $t('general.hints.account_empty.title') }}
    </h3>
    <p class="mt-1 text-sm text-gray-500">
      {{
        $t('general.hints.account_empty.description', {
          max_accounts:
            $store.state.auth.user.subscription.pack.max_accounts -
            $store.state.accounts.accounts.length
        })
      }}
    </p>
    <div class="mt-6">
      <GenericButton type="clear" icon="fal fa-plus">
        {{
          $t('general.entityAction', {
            control: $t('general.controls.create'),
            entity: $t('entities.account')
          })
        }}
      </GenericButton>
    </div>
  </div>

  <DeleteModal
    :is-open="showRemoveEventModal"
    :label="deleteModalLabel"
    :text="deleteModalText"
    @action="processDeleteModalAction"
  />
</template>

<script>
  import http from '../http/index.js'
  import { mapActions } from 'vuex'
  import TableSkeleton from '@/components/skeletons/TableSkeleton.vue'
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  } from '@headlessui/vue'
  import {
    BadgeCheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CollectionIcon,
    SearchIcon,
    SortAscendingIcon,
    StarIcon,
    UserCircleIcon,
    MailIcon,
    LockClosedIcon,
    CalendarIcon
  } from '@heroicons/vue/solid'
  import moment from 'moment'
  import { MenuAlt1Icon, XIcon } from '@heroicons/vue/outline'
  import _ from 'lodash'
  import Placeholder from '@/components/media/Placeholder.vue'
  import GenericButton from '@/components/buttons/GenericButton.vue'
  import DeleteModal from '@/components/modals/DeleteModal.vue'
  import { useI18n } from 'vue-i18n'

  export default {
    name: 'HomePage.vue',
    components: {
      DeleteModal,
      GenericButton,
      Placeholder,
      Disclosure,
      TableSkeleton,
      LockClosedIcon,
      DisclosureButton,
      DisclosurePanel,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      BadgeCheckIcon,
      ChevronDownIcon,
      ChevronRightIcon,
      CollectionIcon,
      MenuAlt1Icon,
      SearchIcon,
      SortAscendingIcon,
      StarIcon,
      XIcon,
      UserCircleIcon,
      MailIcon,
      CalendarIcon
    },
    methods: {
      ...mapActions('accounts', ['setAccounts', 'setActiveAccount']),
      async fetchAccounts() {
        this.loading = true

        await http
          .get('customer/accounts')
          .then(response => {
            this.accounts = response.data.data
            this.setAccounts(response.data.data)
          })
          .catch(errors => {
            console.log(errors)
          })
          .finally(() => {
            this.loading = false
          })
      },
      formatDate(date) {
        return moment(date).format('D/MM/Y')
      },
      showMenu(event, account) {
        this.menuPosition = { x: event.clientX, y: event.clientY }
        this.menuVisible = true
        this.selectedAccount = account
        document.addEventListener('click', this.hideMenu)
      },
      hideMenu() {
        this.menuVisible = false
        document.removeEventListener('click', this.hideMenu)
      },
      viewItem(account) {
        console.log("Voir l'élément", account)
      },
      editItem(account) {
        console.log("Éditer l'élément", account)
      },
      deleteItem(account) {
        this.showRemoveEventModal = true
        this.deleteModalLabel = account.name
        this.selectedAccount = account
      },
      addMember(account) {
        console.log('Ajouter un membre à', account)
      },
      processDeleteModalAction(action) {
        this.loading = true
        if (action !== null) {
          http
            .delete('customer/accounts/' + this.selectedAccount.slug)
            .then(response => {
              this.accounts = []
              this.fetchAccounts()
            })
            .catch(error => {
              console.log(error.response.data)
            })
        }
        this.loading = false

        this.showRemoveEventModal = false
      }
    },
    data() {
      return {
        loading: false,
        menuVisible: false,
        showRemoveEventModal: false,
        deleteModalLabel: null,
        events: [],
        accounts: [],
        menuPosition: { x: 0, y: 0 },
        selectedAccount: null,
        filter: 'date'
      }
    },

    setup() {
      const { t } = useI18n()
      const deleteModalText = t('general.delete_confirmation', { entity: t('entities.account', 1) })

      return {
        deleteModalText
      }
    },
    async mounted() {
      await this.fetchAccounts()

      if (this.filter === 'date') {
        this.events = _.orderBy(this.events, 'created_at')
      } else if (this.filter === 'name') {
        this.events = _.orderBy(this.events, 'name', 'asc')
      }
    }
  }
</script>

<style scoped></style>
