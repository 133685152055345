<template>
  <div
    :class="{
      'lg:border-l': $store.state.appSettings.direction === 'ltr',
      'lg:border-r': $store.state.appSettings.direction === 'rtl'
    }"
    class="bg-gray-50 pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-gray-200 xl:pr-0"
  >
    <div class="px-6 lg:w-80">
      <div class="pt-6 pb-2">
        <h2 class="text-sm font-semibold">{{ $t('labels.activity') }}</h2>
      </div>
      <div>
        <ul role="list" class="divide-y divide-gray-200">
          <li v-for="item in activityItems" :key="item.commit" class="py-4">
            <div class="flex space-x-3">
              <img class="h-6 w-6 rounded-full" src="@/assets/Obi-Wan-icon.png" alt="" />
              <div class="flex-1 space-y-1">
                <div class="flex items-center justify-between">
                  <h3 class="text-sm font-medium">You</h3>
                  <p class="text-sm text-gray-500">{{ item.time }}</p>
                </div>
                <p class="text-sm text-gray-500">
                  Deployed {{ item.project }} ({{ item.commit }} in master) to
                  {{ item.environment }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="py-4 text-sm border-t border-gray-200">
          <a href="#" class="text-primary-600 font-semibold hover:text-primary-900"
            >View all activity <span aria-hidden="true">&rarr;</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const activityItems = [
    // { project: 'Oo', commit: '2d89f0c8', environment: 'production', time: '1h' }
  ]
  export default {
    name: 'ActivityFeed',

    setup() {
      return {
        activityItems
      }
    }
  }
</script>

<style scoped></style>
