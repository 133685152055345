<template>
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 165 165"
    style="enable-background: new 0 0 165 165"
    xml:space="preserve"
  >
    <circle :class="bcg" cx="82.5" cy="82.5" r="82.5" />
    <path
      class="fill-white"
      d="M50,117.5V46.3h23.9c15.1,0,22.6,6.5,22.6,19.5c0,3.1-0.8,5.9-2.3,8.5c-1.5,2.5-3.6,4.4-6.3,5.7
	c3.4,1,6,3,7.9,6c1.9,3,2.8,6.6,2.8,10.7c0,13.9-8.1,20.9-24.2,20.9H50z M85.1,66.3c0-6.8-3.6-10.2-10.9-10.2H60.6V76h14
	c3.9,0,6.6-1,8.2-2.8C84.3,71.3,85.1,69,85.1,66.3z M60.6,85.1v22.7h14.2c8.3,0,12.4-3.8,12.4-11.4c0-3.8-1-6.6-2.9-8.5
	c-1.9-1.9-5.1-2.8-9.4-2.8H60.6z"
    />
    <path
      class="fill-white"
      d="M103.2,116.6c-1.4-1.4-2-3-2-4.9c0-1.9,0.7-3.5,2-4.9c1.4-1.4,3-2,4.9-2s3.5,0.7,4.9,2c1.4,1.4,2,3,2,4.9
	c0,1.9-0.7,3.5-2,4.9c-1.4,1.4-3,2-4.9,2S104.5,118,103.2,116.6z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'Placeholder',
    props: ['bcg']
  }
</script>

<style type="text/css">
  .st0 {
    fill: #37306b;
  }

  .st1 {
    fill: #ffffff;
  }
</style>
