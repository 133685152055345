import { createRouter, createWebHistory } from 'vue-router'
import EventRoutes from '@/router/events.js'
import AccountRoutes from '@/router/accounts.js'
import store from '../store'
import i18n from '@/utils/i18n.js'
import MainRoutes from '@/router/mainRoutes.js'
import DevRoutes from '@/router/dev.js'
import UserRoutes from '@/router/users.js'

const { t } = i18n.global

const routes = [...MainRoutes, ...EventRoutes, ...AccountRoutes, ...DevRoutes, ...UserRoutes]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const documentTitle = to.meta.title ? to.meta.title : 'Home'
  document.title = `Bifrost EVENTS | ${documentTitle}`

  const locale = localStorage.getItem('locale') || 'en' // 'en' est la langue par défaut

  document.documentElement.lang = locale
  document.documentElement.dir = locale === 'ar' ? 'rtl' : 'ltr'
  const dir = locale === 'ar' ? 'rtl' : 'ltr'

  store.dispatch('appSettings/updateDirection', dir)

  if (to.meta.middleware === 'guest') {
    if (store.state.auth.authenticated) {
      next({ name: t('general.pages.dashboard') })
    } else {
      next()
    }
  } else {
    if (store.state.auth.authenticated && to.path !== '/login') {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
})

export default router
