export default {
  namespaced: true,
  state: {
    isDrawerOpen: false,
    dynamicComponentName: null,
    drawerTitle: null,
    drawerDescription: null
  },
  mutations: {
    openDrawer(state) {
      state.isDrawerOpen = true
    },
    closeDrawer(state) {
      state.isDrawerOpen = false
    },
    setDynamicComponent(state, componentName) {
      state.dynamicComponentName = componentName
    },
    setDrawerTitle(state, drawerTitle) {
      state.drawerTitle = drawerTitle
    },
    setDrawerDescription(state, drawerDescription) {
      state.drawerDescription = drawerDescription
    }
  }
}
