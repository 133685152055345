import CreateAccount from '@/pages/accounts/CreateAccount.vue'
import ListEvents from '@/pages/accounts/ListEvents.vue'
import Dashboard from '../pages/Dashboard.vue'
import store from '@/store/index.js'
import i18n from '@/utils/i18n.js'

const { t } = i18n.global

const AccountRoutes = [
  {
    path: '/accounts',
    component: Dashboard,
    children: [
      {
        path: 'create',
        name: 'CreateAccount',
        component: CreateAccount,
        meta: {
          middleware: 'auth',
          title: t('general.entityAction', {
            control: t('general.controls.create'),
            entity: t('entities.account')
          }),
          pageName: t('general.entityAction', {
            control: t('general.controls.create'),
            entity: t('entities.account')
          })
        }
      },
      {
        path: ':slug/events',
        name: 'ShowAccountEvents',
        component: ListEvents,
        meta: {
          middleware: 'auth',
          title: t('entities.event', 2) + ' - ' + store.state.accounts.activeAccount.name,
          pageName: t('entities.event', 2) + ' - ' + store.state.accounts.activeAccount.name,
          breadcrumbs: [
            {
              name: t('general.pages.dashboard'),
              href: '/',
              current: false
            },
            {
              name: t('entities.account', 2),
              href: '/accounts',
              current: false
            },
            {
              name: store.state.accounts.activeAccount.name,
              href: '#',
              current: true
            }
          ]
        }
      }
    ]
  }
]

export default AccountRoutes
