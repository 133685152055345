export default {
  namespaced: true,
  state: {
    shown: false,
    type: '',
    title: '',
    body: ''
  },
  mutations: {
    SHOW_TOAST(state, { type, title, body }) {
      state.shown = true
      state.type = type
      state.title = title
      state.body = body
    },
    HIDE_TOAST(state) {
      state.shown = false
      state.type = ''
      state.title = ''
      state.body = ''
    }
  },
  actions: {
    showToast({ commit }, payload) {
      commit('SHOW_TOAST', payload)
    },
    hideToast({ commit }) {
      commit('HIDE_TOAST')
    }
  }
}
