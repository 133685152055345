<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 mx-auto w-1/2">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <router-link to="/">
        <img class="mx-auto h-12 w-auto" src="@/assets/logo.svg" alt="Bifrost Events" />
      </router-link>
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Mot de passe oublié</h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <ErrorComponent v-if="errors.length" :errors="errors" class="my-3" />
        <div v-if="!processing">
          <div class="my-4">
            <SuccessComponent v-if="message" :message="message" />
          </div>
          <div class="mb-2">
            <label for="email" class="block text-sm font-medium text-gray-700">
              {{ $t('labels.email') }}
            </label>
            <div class="mt-1">
              <input
                :disabled="message"
                id="email"
                name="email"
                v-model="email"
                type="email"
                autocomplete="email"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <button
              :disabled="this.message"
              role="button"
              @click="sendPasswordRequest"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
            >
              Envoyer l'email
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import axios from 'axios'
  import ErrorComponent from '@/components/alerts/ErrorComponent.vue'
  import SuccessComponent from '@/components/alerts/SuccessComponent.vue'

  export default {
    name: 'ForgotPassword',
    components: { ErrorComponent, SuccessComponent },
    data() {
      return {
        email: '',
        message: null,
        errors: [],
        processing: false
      }
    },
    methods: {
      ...mapActions({
        signIn: 'auth/login'
      }),
      async sendPasswordRequest() {
        if (!this.message) {
          this.processing = true
          this.errors = []
          await axios
            .post(import.meta.env.VITE_API_ENDPOINT + 'customer/passwords/forgot', {
              email: this.email
            })
            .then(({ data }) => {
              this.errors = []
              this.message = data.message
            })
            .catch(error => {
              this.errors.push(error.response.data)
            })
            .finally(() => {
              this.processing = false
            })
        }
      }
    }
  }
</script>

<style scoped>
  .spinner_P7sC {
    transform-origin: center;
    animation: spinner_svv2 0.75s infinite linear;
  }

  body {
    @apply bg-gray-100;
  }

  @keyframes spinner_svv2 {
    100% {
      transform: rotate(360deg);
    }
  }
</style>
