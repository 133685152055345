<template>
  <main class="flex-grow w-full lg:flex">
    <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
      <!-- Breadcrumb -->
      <nav aria-label="Breadcrumb" class="bg-white border-b border-secondary-gray-200 xl:hidden">
        <div class="max-w-3xl mx-auto py-3 px-4 flex items-start sm:px-6 lg:px-8">
          <a
            href="#"
            class="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-secondary-gray-900"
          >
            <ChevronLeftIcon class="h-5 w-5 text-secondary-gray-400" aria-hidden="true" />
            <span>Settings</span>
          </a>
        </div>
      </nav>

      <div class="flex-1 flex xl:overflow-hidden">
        <!-- Secondary sidebar -->
        <nav
          aria-label="Sections"
          class="hidden flex-shrink-0 w-96 bg-white border-r border-secondary-gray-200 xl:flex xl:flex-col"
        >
          <div class="flex-shrink-0 h-16 px-6 border-b border-secondary-gray-200 flex items-center">
            <p class="text-lg font-medium text-secondary-gray-900">Settings</p>
          </div>
          <div class="flex-1 min-h-0 overflow-y-auto">
            <a
              v-for="item in subNavigation"
              :key="item.name"
              :href="item.href"
              :class="[
                item.current
                  ? 'bg-secondary-50 bg-opacity-50'
                  : 'hover:bg-secondary-50 hover:bg-opacity-50',
                'flex p-6 border-b border-secondary-gray-200'
              ]"
              :aria-current="item.current ? 'page' : undefined"
            >
              <component
                :is="item.icon"
                class="flex-shrink-0 -mt-0.5 h-6 w-6 text-secondary-gray-400"
                aria-hidden="true"
              />
              <div class="ml-3 text-sm">
                <p class="font-medium text-secondary-gray-900">{{ item.name }}</p>
                <p class="mt-1 text-secondary-gray-500">{{ item.description }}</p>
              </div>
            </a>
          </div>
        </nav>

        <!-- Main content -->
        <div class="flex-1 xl:overflow-y-auto">
          <component :is="activeTab" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import { shallowRef } from 'vue'
  import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import {
    BellIcon,
    BookmarkAltIcon,
    CashIcon,
    CogIcon,
    FireIcon,
    HomeIcon,
    InboxIcon,
    KeyIcon,
    MenuIcon,
    PhotographIcon,
    SearchCircleIcon,
    UserIcon,
    ViewGridAddIcon,
    XIcon
  } from '@heroicons/vue/outline'
  import { ChevronLeftIcon } from '@heroicons/vue/solid'
  import Account from '@/components/settings/Account.vue'

  const navigation = [
    { name: 'Home', href: '#', icon: HomeIcon },
    { name: 'Trending', href: '#', icon: FireIcon },
    { name: 'Bookmarks', href: '#', icon: BookmarkAltIcon },
    { name: 'Messages', href: '#', icon: InboxIcon },
    { name: 'Profile', href: '#', icon: UserIcon }
  ]
  const subNavigation = [
    {
      name: 'Mon compte',
      description:
        'Gérez vos informations personnelles et les paramètres de votre compte en toute simplicité.',
      href: '#',
      icon: CogIcon,
      current: true
    },
    {
      name: 'Notifications',
      description:
        'Restez informé(e) des dernières activités et mises à jour liées à votre compte.',
      href: '#',
      icon: BellIcon,
      current: false
    },
    {
      name: 'Sécurité',
      description:
        'Protégez votre compte en configurant des options de sécurité avancées et en suivant nos recommandations.',
      href: '#',
      icon: KeyIcon,
      current: false
    },
    {
      name: 'Apparence',
      description:
        "Personnalisez l'apparence de votre interface utilisateur selon vos préférences et votre style.",
      href: '#',
      icon: PhotographIcon,
      current: false
    },
    {
      name: 'Facturation',
      description:
        'Gérez vos informations de paiement, consultez vos factures et mettez à jour vos options de facturation.',
      href: '#',
      icon: CashIcon,
      current: false
    },
    {
      name: 'Intégrations',
      description:
        "Explorez nos options d'intégration et connectez facilement nos services à d'autres applications.",
      href: '#',
      icon: ViewGridAddIcon,
      current: false
    },
    {
      name: 'Ressources Additionnelles',
      description:
        'Découvrez des ressources supplémentaires pour améliorer votre expérience et maximiser vos bénéfices.',
      href: '#',
      icon: SearchCircleIcon,
      current: false
    }
  ]

  export default {
    name: 'Create',
    components: {
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      ChevronLeftIcon,
      MenuIcon,
      XIcon
    },
    setup() {
      const mobileMenuOpen = shallowRef(false)

      return {
        navigation,
        subNavigation,
        mobileMenuOpen
      }
    },
    data() {
      return {
        activeTab: Account
      }
    },
    mounted() {
      console.log(this.$route.params.accountId)
    }
  }
</script>
