import Dashboard from '@/pages/Dashboard.vue'
import UsersIndex from '@/pages/users/UsersIndex.vue'
import i18n from '@/utils/i18n.js'
import store from '@/store/index.js'

const { t } = i18n.global
const UserRoutes = [
  {
    path: '/users',
    component: Dashboard,
    meta: {
      middleware: 'auth',
      title: t('entities.user', 2),
      pageName: t('entities.user', 2),
      breadcrumbs: [
        {
          name: t('general.pages.dashboard'),
          href: '/',
          current: false
        },
        {
          name: t('entities.user', 2),
          href: '/users',
          current: true
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'UsersIndex',
        component: UsersIndex,
        meta: {
          middleware: 'auth',
          title: t('entities.user', 2),
          pageName: t('entities.user', 2),
          breadcrumbs: [
            {
              name: t('general.pages.dashboard'),
              href: '/',
              current: false
            },
            {
              name: t('entities.user', 2),
              href: '/users',
              current: true
            }
          ]
        }
      }
    ]
  }
]

export default UserRoutes
