export default {
  namespaced: true,
  state: {
    newEvent: {}
  },
  mutations: {
    SET_EVENT(state, event) {
      state.newEvent = event
    }
  },
  actions: {
    setEvent({ commit }, event) {
      commit('SET_EVENT', event)
    },
    resetEvent({ commit }) {
      commit('SET_EVENT', {})
    }
  }
}
