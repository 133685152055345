<template>
    <div class="m-4 flex flex-col gap-3">
        <ErrorComponent v-if="errors.length" :errors="errors" />
        <div>
            <label for="title" class="block text-sm font-medium leading-6 text-gray-900">
                {{
            $t('labels.title')
        }}
            </label>
            <input type="text" id="title" v-model="session.title"
                class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
        </div>
        <div>
            <label for="description" class="block text-sm font-medium leading-6 text-gray-900">
                {{
            $t('labels.description')
        }}
            </label>
            <textarea id="description" v-model="session.description"
                class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
        </div>
        <Listbox as="div" v-model="session.type">
            <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Type de session</ListboxLabel>
            <div class="relative mt-2">
                <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6">
                    <span class="block truncate">{{ selectedSessionTypeName }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <i class="fas fa-chevron-down h-5 w-5 text-gray-400"></i>
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ListboxOption as="template" v-for="sessionType in sessionTypes" :key="sessionType.code"
                            :value="sessionType.code" v-slot="{ active, selected }">
                            <li
                                :class="[active ? 'bg-primary-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 group']">
                                <div>
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
            sessionType.name }}</span>
                                    <span class="block text-xs text-gray-500 group-hover:text-white">{{
            sessionType.description }}</span>
                                </div>
                                <span v-if="selected"
                                    :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <i class="fas fa-check h-5 w-5"></i>
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
        <div>
            <label class="block text-sm font-medium leading-6 text-gray-900" for="start_datetime">
                {{ $t('labels.date_start') }}
            </label>
            <input v-model="session.start_datetime"
                class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                id="start_datetime" type="datetime-local" :min="$store.state.accounts.activeEvent.start_date"
                :max="$store.state.accounts.activeEvent.end_date" />
        </div>
        <div>
            <label class="block text-sm font-medium leading-6 text-gray-900" for="end_datetime">
                {{ $t('labels.date_end') }}
            </label>
            <input v-model="session.end_datetime"
                class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                id="end_datetime" type="datetime-local" :min="$store.state.accounts.activeEvent.start_date"
                :max="$store.state.accounts.activeEvent.end_date" />
        </div>

        <GenericButton type="primary" @click="validateAndStoreSession">{{ $t('general.controls.store') }}
        </GenericButton>
    </div>
</template>

<script>
import http from "@/http/index.js"
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import GenericButton from "@/components/buttons/GenericButton.vue"
import ErrorComponent from "@/components/alerts/ErrorComponent.vue"

export default {
    name: "CreateSession",
    components: { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, GenericButton, ErrorComponent },
    data() {
        return {
            session: {
                type: null, // initialiser à null
                start_datetime: null,
                end_datetime: null,
                title: '',
                description: ''
            },
            errors: [],
            sessionTypes: []
        }
    },
    computed: {
        selectedSessionTypeName() {
            const selectedType = this.sessionTypes.find(type => type.id === this.session.type);
            return selectedType ? selectedType.name : 'Select a session type';
        }
    },
    mounted() {
        this.fetchSessionTypes();
    },
    methods: {
        fetchSessionTypes() {
            http.get('customer/events/sessions-types')
                .then((response) => {
                    this.sessionTypes = response.data;
                })
                .catch(error => {
                    this.errors.push('Failed to load session types');
                });
        },
        validateSessionDates() {
            const event = this.$store.state.accounts.activeEvent;
            const sessionStart = new Date(this.session.start_datetime);
            const sessionEnd = new Date(this.session.end_datetime);
            const eventStart = new Date(event.start_date);
            const eventEnd = new Date(event.end_date);

            this.errors = [];

            if (sessionStart < eventStart || sessionStart > eventEnd) {
                this.errors.push({
                    message: 'Invalid start date',
                    errors: [`Start date must be between ${event.start_date} and ${event.end_date}`]
                });
            }

            if (sessionEnd < eventStart || sessionEnd > eventEnd) {
                this.errors.push({
                    message: 'Invalid end date',
                    errors: [`End date must be between ${event.start_date} and ${event.end_date}`]
                });
            }

            if (sessionEnd < sessionStart) {
                this.errors.push({
                    message: 'Invalid date range',
                    errors: [{
                        "start_date": 'End date must be after the start date'
                    }]
                });
            }

            return this.errors.length === 0;
        },
        validateAndStoreSession() {
            if (this.validateSessionDates()) {
                this.storeSession();
            }
        },
        storeSession() {
            const eventId = this.$store.state.accounts.activeEvent.id;
            http.post(`/customer/events/${eventId}/planning/sessions`, this.session)
                .then((response) => {
                    this.errors = [];
                    console.log(response.data)
                })
                .catch((errors) => {
                    this.errors.push(errors.response.data)
                })
        }
    }
}
</script>
