<template>
  <div class="animate-pulse space-y-4">
    <!-- Utilisateur 1 -->
    <div class="flex items-center space-x-4">
      <div class="rounded-full bg-gray-400 h-12 w-12"></div>
      <div class="flex-1 space-y-2">
        <div class="h-2 bg-gray-400 rounded"></div>
        <div class="h-2 bg-gray-400 rounded"></div>
      </div>
    </div>
    <!-- Répéter pour d'autres utilisateurs -->
    <div class="flex items-center space-x-4">
      <div class="rounded-full bg-gray-400 h-12 w-12"></div>
      <div class="flex-1 space-y-2">
        <div class="h-2 bg-gray-400 rounded"></div>
        <div class="h-2 bg-gray-400 rounded"></div>
      </div>
    </div>
    <div class="flex items-center space-x-4">
      <div class="rounded-full bg-gray-400 h-12 w-12"></div>
      <div class="flex-1 space-y-2">
        <div class="h-2 bg-gray-400 rounded"></div>
        <div class="h-2 bg-gray-400 rounded"></div>
      </div>
    </div>
  </div>
</template>
