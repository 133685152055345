<template>
  <div class="p-5 space-y-4">
    <div
      v-for="notification in $store.state.notifications.notifications"
      :key="notification.id"
      :class="[
        'p-4 rounded-lg transition-all hover:shadow-lg relative',
        notification.read_at
          ? 'bg-white'
          : 'bg-secondary-50 hover:bg-secondary-100',
      ]"
    >
      <div class="flex items-center">
        <div
          :class="[
            'flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center',
            notification.data.bg
          ]"
        >
          <i
            :class="['fas', notification.data.color, notification.data.icon ?? 'fa-bell']"
          ></i>
        </div>
        <div class="flex-1 ml-4">
          <h3 class="text-lg font-semibold text-gray-900">
            {{ $t(notification.data.message) }}
          </h3>
          <p class="text-sm text-gray-500">
            {{ $moment(notification.created_at).format('DD/MM/Y H:m') }} (
            {{ $moment(notification.created_at).fromNow() }})
          </p>
        </div>
        <div
          v-if="notification.read_at === null"
          class="absolute top-0 right-0 mt-2 mr-2 text-sm font-medium text-secondary-700 bg-secondary-100 rounded-full px-3 py-1"
        >
          <i class="fal fa-eye-slash"></i> {{ $t('notifications.status.unread') }}
        </div>
      </div>

      <!-- Section spécifique selon le type de notification -->
      <div class="mt-4">
        <router-link
          v-if="notification.data.type === 'download'"
          target="_blank"
          :to="{ name: 'Download', query: { fileUrl: notification.data.url } }"
          class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:ring-2 focus:ring-primary-500"
        >
          <svg
            class="w-4 h-4 mr-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z"
            />
            <path
              d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
            />
          </svg>
          {{ $t('general.controls.download') }}
        </router-link>

        <div v-else-if="notification.data.type === 'alert'" class="text-red-600">
          <i class="fal fa-exclamation-triangle"></i> {{ $t('notifications.types.alert') }}
        </div>

        <div v-else-if="notification.data.type === 'info'" class="text-blue-600">
          <i class="fal fa-info-circle"></i> {{ $t('notifications.types.info') }}
        </div>

        <div v-else-if="notification.data.type === 'control'" class="text-green-600">
          <i class="fal fa-cogs"></i> {{ $t('notifications.types.control') }}
        </div>

        <div v-else-if="notification.data.type === 'comment'" class="text-yellow-600">
          <i class="fal fa-comment-alt"></i> {{ $t('notifications.types.comment') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import http from '../http/index.js'

onMounted(async () => {
  try {
    const response = await http.post('customer/notifications?markAsRead=true')
    console.log('Notification status updated:', response.data)
  } catch (error) {
    console.error('Error updating notifications:', error)
  }
})
</script>

<style scoped>
/* Additional custom styles */
.notification-card {
  transition: box-shadow 0.2s ease-in-out;
}

.notification-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
