<template>
  <div class="m-4 flex flex-col gap-3">
    <ErrorComponent v-if="errors.length" :errors="errors" />

    <label class="block text-sm font-medium text-gray-700">
      {{ $t('entities.attendee', 20) }} file
    </label>
    <div
      class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <div class="space-y-1 text-center">
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="flex text-sm text-gray-600">
          <label
            for="file-upload"
            class="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
          >
            <span>{{ $t('general.controls.upload_a_file') }}</span>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              class="sr-only"
              @change="handleFileChange"
            />
          </label>
          <p class="pl-1">or drag and drop</p>
        </div>
        <p class="text-xs text-gray-500">XLSX , XLSX ou CSV max 10 MB.</p>
        <p v-if="fileName" class="text-sm text-gray-700 mt-2">File: {{ fileName }}</p>
      </div>
    </div>
    <GenericButton @click="submitForm">
      {{ $t('general.controls.upload') }}
    </GenericButton>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import GenericButton from '@/components/buttons/GenericButton.vue'
  import http from '@/http/index.js'
  import { useRoute } from 'vue-router'
  import ErrorComponent from '@/components/alerts/ErrorComponent.vue'

  const file = ref(null)
  const fileName = ref('')

  const errors = ref([])

  const route = useRoute()

  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      file.value = selectedFile
      fileName.value = selectedFile.name
    }
  }

  const handleDrop = event => {
    const droppedFile = event.dataTransfer.files[0]
    if (droppedFile) {
      file.value = droppedFile
      fileName.value = droppedFile.name
    }
  }

  const submitForm = () => {
    if (file.value) {
      const formData = new FormData()
      formData.append('file', file.value)

      http
        .post('/customer/events/' + route.params.id + '/attendees/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data)
        })
        .catch(error => {
          errors.value.push(error.response.data)
        })
    } else {
      console.log('No file selected.')
    }
  }
</script>
