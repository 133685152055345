<template>
  <EmptyState :entity="$t('entities.report', 3).toLowerCase()" icon="chart-pie" />
</template>

<script>
  import EmptyState from '@/components/hints/EmptyState.vue'
  export default {
    name: 'Reports',
    components: { EmptyState }
  }
</script>
