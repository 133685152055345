<template>
  <TransitionRoot :show="isDialogOpen" as="template" @after-leave="rawQuery = ''">
    <Dialog
      as="div"
      class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20"
      @close="$emit('close')"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-95"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 scale-95"
        enter-to="opacity-100 scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-95"
        v-if="isDialogOpen"
      >
        <Combobox
          as="div"
          class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          @update:modelValue="onSelect"
        >
          <div class="relative">
            <SearchIcon
              class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <ComboboxInput
              class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
              :placeholder="$t('general.controls.search') + '...'"
              @input="updateQuery"
            />
          </div>

          <ComboboxOptions
            v-if="showResults"
            static
            class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
          >
            <li v-if="filteredProjects.length > 0">
              <h2 class="text-xs font-semibold text-gray-900">{{ $t('entities.event', 2) }}</h2>
              <ul class="-mx-4 mt-2 text-sm text-gray-700">
                <ComboboxOption
                  v-for="project in filteredProjects"
                  :key="project.id"
                  :value="project"
                  as="template"
                  v-slot="{ active }"
                >
                  <li
                    :class="[
                      'flex cursor-default select-none items-center px-4 py-2',
                      active && 'bg-secondary-600 text-white'
                    ]"
                  >
                    <FolderIcon
                      :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400']"
                      aria-hidden="true"
                    />
                    <span class="ml-3 flex-auto truncate">{{ project.name }}</span>
                  </li>
                </ComboboxOption>
              </ul>
            </li>
            <li v-if="filteredUsers.length > 0">
              <h2 class="text-xs font-semibold text-gray-900">{{ $t('entities.user', 2) }}</h2>
              <ul class="-mx-4 mt-2 text-sm text-gray-700">
                <ComboboxOption
                  v-for="user in filteredUsers"
                  :key="user.id"
                  :value="user"
                  as="template"
                  v-slot="{ active }"
                >
                  <li
                    :class="[
                      'flex cursor-default select-none items-center px-4 py-2',
                      active && 'bg-secondary-600 text-white'
                    ]"
                  >
                    <img :src="user.imageUrl" alt="" class="h-6 w-6 flex-none rounded-full" />
                    <span class="ml-3 flex-auto truncate">{{ user.name }}</span>
                  </li>
                </ComboboxOption>
              </ul>
            </li>
          </ComboboxOptions>

          <UsersListSkeleton class="p-6" v-if="loading" />

          <div v-if="rawQuery === '?'" class="py-14 px-6 text-center text-sm sm:px-14">
            <SupportIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
            <p class="mt-4 font-semibold text-gray-900">
              {{ $t('general.hints.searching.title') }}
            </p>
            <p class="mt-2 text-gray-500">{{ $t('general.hints.searching.body') }}</p>
          </div>

          <EmptyState
            class="p-6"
            entity="resultat"
            icon="magnifying-glass-arrows-rotate"
            v-if="
              query !== '' &&
              rawQuery !== '?' &&
              filteredProjects.length === 0 &&
              filteredUsers.length === 0
            "
          />

          <div class="flex flex-wrap items-center bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
            Type
            <kbd
              :class="[
                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                rawQuery.startsWith('#')
                  ? 'border-secondary-600 text-secondary-600'
                  : 'border-gray-400 text-gray-900'
              ]"
              >#</kbd
            >
            <span class="sm:hidden">for projects,</span>
            <span class="hidden sm:inline">to access projects,</span>
            <kbd
              :class="[
                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                rawQuery.startsWith('>')
                  ? 'border-secondary-600 text-secondary-600'
                  : 'border-gray-400 text-gray-900'
              ]"
              >&gt;</kbd
            >
            for users, and
            <kbd
              :class="[
                'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                rawQuery === '?'
                  ? 'border-secondary-600 text-secondary-600'
                  : 'border-gray-400 text-gray-900'
              ]"
              >?</kbd
            >
            for help.
          </div>
        </Combobox>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { ref, computed, watch } from 'vue'
  import { SearchIcon } from '@heroicons/vue/solid'
  import UsersListSkeleton from '@/components/skeletons/UsersListSkeleton.vue'
  import EmptyState from './hints/EmptyState.vue'
  import { ExclamationIcon, FolderIcon, SupportIcon } from '@heroicons/vue/outline'
  import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot
  } from '@headlessui/vue'
  import http from '@/http/index.js'

  import { useRouter } from 'vue-router'

  const router = useRouter()

  const rawQuery = ref('')
  const loading = ref(false)
  const query = computed(() => rawQuery.value.toLowerCase().replace(/^[#>]/, ''))
  const projects = ref([])
  const users = ref([])
  const showResults = computed(
    () => !loading.value && (filteredProjects.value.length > 0 || filteredUsers.value.length > 0)
  )

  const fetchProjects = async query => {
    loading.value = true
    try {
      const response = await http.get(`customer/search?search=${query}&projects=true`)
      projects.value = response.data
    } catch (error) {
      console.error('Error fetching projects:', error)
    } finally {
      loading.value = false
    }
  }

  const fetchUsers = async query => {
    loading.value = true
    try {
      const response = await http.get(`customer/search?search=${query}&users=true`)
      users.value = response.data
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      loading.value = false
    }
  }

  const updateQuery = async event => {
    rawQuery.value = event.target.value
    if (rawQuery.value.startsWith('#')) {
      await fetchProjects(query.value)
    } else if (rawQuery.value.startsWith('>')) {
      await fetchUsers(query.value)
    } else {
      await fetchProjects(query.value)
      await fetchUsers(query.value)
    }
  }

  const filteredProjects = computed(() => (rawQuery.value.startsWith('>') ? [] : projects.value))
  const filteredUsers = computed(() => (rawQuery.value.startsWith('#') ? [] : users.value))

  function onSelect(item) {
    router.push({ name: 'ShowEvent', params: { id: item.id } })
    emit('close')
  }

  const props = defineProps({
    isDialogOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  })

  const emit = defineEmits(['close'])

  watch(
    () => props.isDialogOpen,
    newVal => {
      if (!newVal) {
        rawQuery.value = ''
        projects.value = []
        users.value = []
      }
    }
  )
</script>

<style scoped>
  /* Ajoutez vos styles ici */
</style>
