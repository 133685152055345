<template>
  <div class="relative min-h-full flex flex-col" :class="{ 'justify-center items-center': !authenticated }">
    <Disclosure as="nav" :class="{
    'from-primary-700 to-secondary-700': $store.state.appSettings.direction === 'ltr',
    'from-secondary-700 to-primary-700': $store.state.appSettings.direction === 'rtl'
  }" class="flex-shrink-0 bg-gradient-to-r" v-slot="{ open }" v-if="authenticated">
      <Drawer v-if="isDrawerOpen" @closeDrawer="closeDrawer" :dynamicComponent="dynamicComponentName" />
      <div class="px-2 sm:px-4 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <!-- Logo section -->
          <div class="flex items-center px-2 lg:px-0 xl:w-64">
            <div class="flex-shrink-0">
              <router-link to="/">
                <img class="h-8 w-auto hidden lg:block xl:block" src="@/assets/logo_w.svg" alt="Bifrost Events" />
                <img class="h-8 w-auto block lg:hidden xl:hidden" style="width: 32px" src="@/assets/logo_w_sm.svg"
                  alt="Bifrost Events" />
              </router-link>
            </div>
          </div>

          <!-- Search section -->
          <div class="flex-1 flex justify-center lg:justify-start">
            <div class="w-full px-2 lg:px-6">
              <label for="search" class="sr-only">{{ $t('general.controls.search') }}</label>
              <div class="relative text-secondary-200 focus-within:text-gray-400">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon class="h-5 w-5" aria-hidden="true" />
                </div>
                <input id="search" @click="showSearch"
                  class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-secondary-400 bg-opacity-25 text-secondary-100 placeholder-secondary-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                  :placeholder="$t('general.controls.search')" readonly type="search" />
              </div>
            </div>
          </div>
          <CommandPalette :is-dialog-open="showCommandPalette" @close="showCommandPalette = false" />
          <div class="flex lg:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="bg-secondary-600 inline-flex items-center justify-center p-2 rounded-md text-secondary-400 hover:text-white hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-600 focus:ring-white">
              <span class="sr-only">Open main menu</span>
              <MenuAlt1Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <!-- Links section -->
          <div class="hidden lg:block lg:w-80">
            <div class="flex items-center justify-end">
              <div class="flex">
                <RouterLink v-for="item in navigation" :key="item.name" :to="item.href"
                  class="px-3 py-2 rounded-md text-sm font-medium text-secondary-200 hover:text-white">
                  {{ item.name }}
                </RouterLink>
              </div>
              <Menu as="div" class="ml-4 relative flex-shrink-0">
                <div>
                  <MenuButton
                    class="bg-secondary-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-700 focus:ring-white">
                    <span class="sr-only">Open user menu</span>
                    <BellIconSolid v-if="notifications.length" class="h-6 w-6" />
                    <BellIcon class="h-6 w-6" v-else />
                    <div v-if="notifications.length" class="w-2 h-2 bg-mantis-400 rounded-full absolute top-0 right-0">
                    </div>
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems as="ul" :class="{
    'origin-top-left left-0': $store.state.appSettings.direction === 'rtl',
    'origin-top-right right-0': $store.state.appSettings.direction === 'ltr'
  }"
                    class="absolute mt-2 w-[320px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <div class="border-b bg-primary-600 flex justify-between px-3 rounded-t py-3">
                      <span class="text-sm text-white font-medium"> Notifications </span>

                      <router-link to="settings" class="text-white hover:underline text-sm">
                        <CogIcon class="w-4 h-4" />
                      </router-link>
                    </div>
                    <MenuItem as="li" v-for="notification in notifications" :key="notification.id"
                      v-if="notifications.length">
                    <router-link :to="{ name: 'Download', query: { fileUrl: notification.data.url } }"
                      class="py-2 px-2 flex hover:bg-gray-100 transition">
                      <div class="w-10 h-10 aspect-square rounded-full flex items-center justify-center self-center"
                        :class="notification.data.bg">
                        <i :class="[
    'fal self-center',
    notification.data.icon,
    'text-' + notification.data.color
  ]"></i>
                      </div>
                      <div class="ml-3 flex justify-between gap-2">
                        <div>
                          <small>{{ $t(notification.data.message).substr(-1, 10) }}</small>
                          <span class="text-xs text-gray-500 relative float-right"
                            v-text="notification.created_at"></span>
                        </div>
                        <div class="self-center" v-if="!notification.read_at">
                          <div class="w-2 h-2 bg-mantis-400 rounded-full"></div>
                        </div>
                      </div>
                    </router-link>
                    </MenuItem>
                    <MenuItem as="div" v-else>
                    <div class="text-center my-4">
                      <BellIcon class="mx-auto h-12 w-12 text-gray-400" />
                      <h3 class="mt-2 text-sm font-medium text-gray-900">
                        No unread notifications
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">You have no notifications</p>
                    </div>
                    </MenuItem>

                    <div class="text-center rounded-b py-2 border-t">
                      <router-link to="/notifications" class="text-primary-600 hover:underline text-sm">
                        Voir tout
                      </router-link>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
              <Menu as="div" class="ml-4 relative flex-shrink-0">
                <div>
                  <MenuButton
                    class="bg-secondary-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-700 focus:ring-white">
                    <GlobeAltIcon class="h-6 w-6" />
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems :class="{
    'origin-top-left left-0': $store.state.appSettings.direction === 'rtl',
    'origin-top-right right-0': $store.state.appSettings.direction === 'ltr'
  }"
                    class="absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <MenuItem v-for="(title, item) in appLocales" :key="item" v-slot="{ active }">
                    <a href="#" @click="switchLocale(item)" :class="[
    active ? 'bg-gray-100' : '',
    'block px-4 py-2 text-sm text-gray-700'
  ]">{{ title }}
                    </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
              <Menu as="div" class="ml-4 relative flex-shrink-0">
                <div>
                  <MenuButton
                    class="bg-secondary-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-700 focus:ring-white">
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" src="@/assets/Obi-Wan-icon.png" alt="" />
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems :class="{
    'origin-top-left left-0': $store.state.appSettings.direction === 'rtl',
    'origin-top-right right-0': $store.state.appSettings.direction === 'ltr'
  }"
                    class="absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <router-link :to="item.action" :class="[
    active ? 'bg-gray-100' : '',
    'block px-4 py-2 text-sm text-gray-700'
  ]">{{ item.name }}
                    </router-link>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <vue-progress-bar></vue-progress-bar>
      <Breadcrumbs />

      <DisclosurePanel class="lg:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[
    item.current
      ? 'text-white bg-secondary-800'
      : 'text-secondary-200 hover:text-secondary-100 hover:bg-secondary-600',
    'block px-3 py-2 rounded-md text-base font-medium'
  ]" :aria-current="item.current ? 'page' : undefined">{{ item.name }}
          </DisclosureButton>
        </div>
        <div class="pt-4 pb-3 border-t border-secondary-800">
          <div class="px-2 space-y-1">
            <DisclosureButton v-for="item in userNavigation" :key="item.name" @click="logout()" as="a" :href="item.href"
              class="block px-3 py-2 rounded-md text-base font-medium text-secondary-200 hover:text-secondary-100 hover:bg-secondary-600">
              {{ item.name }}
            </DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <DevMode v-if="isDevMode"/>
    <div class="flex-grow w-full lg:flex" :class="{ 'overflow-hidden': isDrawerOpen }">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <footer class="p-5 flex justify-between items-center border border-top flex-col sm:flex-row bg-white w-full">
      <span class="text-sm text-gray-500">
        <a href="https://bifrost.events" target="_blank" class="text-sm text-gray-500 hover:underline">
          Bifrost Events
        </a>
        ver. 1.0 BETA
      </span>
      <div class="flex justify-between items-center gap-2">
        <router-link to="/terms-of-service" class="text-sm text-gray-500 hover:underline">
          {{ $t('general.pages.terms') }}
        </router-link>
        <router-link to="/privacy" class="text-sm text-gray-500 hover:underline">
          {{ $t('general.pages.privacy') }}
        </router-link>
      </div>
    </footer>
  </div>
  <Toast />
</template>

<script>
import Drawer from '@/components/Drawer.vue'
import Toast from '@/components/alerts/Toast.vue'
import DevMode from '@/components/DevMode.vue'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import {
  BadgeCheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CollectionIcon,
  SearchIcon,
  SortAscendingIcon,
  CheckIcon,
  StarIcon,
  UserCircleIcon,
  UploadIcon,
  MailIcon,
  CogIcon,
  BellIcon as BellIconSolid
} from '@heroicons/vue/solid'
import { GlobeAltIcon, BellIcon, InformationCircleIcon } from '@heroicons/vue/outline'
import { mapState, mapMutations } from 'vuex'

import { MenuAlt1Icon, XIcon } from '@heroicons/vue/outline'
import Breadcrumbs from './components/Breadcrumbs.vue'
import http from '@/http/index.js'
import CommandPalette from '@/components/CommandPalette.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Dashboard',
  components: {
    CommandPalette,
    CogIcon,
    Breadcrumbs,
    Toast,
    DevMode,
    BellIconSolid,
    InformationCircleIcon,
    Disclosure,
    BellIcon,
    Drawer,
    UploadIcon,
    GlobeAltIcon,
    CheckIcon,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BadgeCheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CollectionIcon,
    MenuAlt1Icon,
    SearchIcon,
    SortAscendingIcon,
    StarIcon,
    XIcon,
    UserCircleIcon,
    MailIcon
  },
  mounted() {
    this.getUnreadNotifications()
    this.$Progress.finish()
  },
  created() {
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },
  methods: {
    logout() {
      this.signOut()
      location.reload()
    },
    getUnreadNotifications() {
      if (this.$store.state.auth.authenticated) {
        http.get('/customer/notifications').then(response => {
          this.$store.dispatch('notifications/setNotifications', response.data)
          this.notifications = response.data
        })
      }
    },
    switchLocale(locale) {
      localStorage.setItem('locale', locale)
      location.reload()
    },
    showSearch() {
      this.showCommandPalette = true
    },
    ...mapMutations('drawer', ['closeDrawer'])
  },
  data() {
    return {
      hasUnreadNotifications: true,
      notifications: [],
      showCommandPalette: false,
      isDevMode: import.meta.env.VITE_NODE_ENV === 'development'
    }
  },
  setup() {
    const { t } = useI18n()

    const navigation = [
      { name: t('general.pages.support'), href: '/support', current: false },
      // { name: t('general.pages.calendar'), href: '/calendar', current: false },
      { name: t('general.pages.users'), href: '/users', current: false }
    ]
    const userNavigation = [
      { name: t('general.pages.profile'), action: '/profile' },
      { name: t('general.pages.settings'), action: '/settings' },
      { name: t('general.pages.subscription'), action: '/subscription' },
      { name: t('general.pages.logout'), action: '/logout' }
    ]
    const appLocales = {
      fr: '🇫🇷  Français',
      en: '🇬🇧 English',
      ar: '🇩🇿 عربي'
    }
    return {
      navigation,
      userNavigation,
      appLocales
    }
  },
  computed: {
    authenticated() {
      return this.$store.state.auth.authenticated
    },
    user() {
      return this.$store.state.auth.user
    },
    ...mapState('drawer', ['isDrawerOpen', 'dynamicComponentName'])
  }
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.slide-enter-to {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.slide-enter-from {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.slide-leave-to {
  position: absolute;
  margin-top: 64px;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  margin-top: 64px;
  left: 0;
}

.overflow-hidden {
  overflow: hidden;
}

#app {
  transition: background-color 0.3s ease;
}

#app.filter-dark {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
