<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!--  <nav v-if="steps.length > 1" aria-label="Progress" class="">-->
  <!--    <ol class="rounded-md overflow-hidden lg:flex lg:border-gray-200 lg:rounded-none" role="list">-->
  <!--      <li-->
  <!--        v-for="(step, stepIdx) in steps"-->
  <!--        :key="step.id"-->
  <!--        class="relative overflow-hidden lg:flex-1"-->
  <!--      >-->
  <!--        <div-->
  <!--          :class="[-->
  <!--            stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',-->
  <!--            stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',-->
  <!--            'border border-gray-200 overflow-hidden lg:border-0'-->
  <!--          ]"-->
  <!--        >-->
  <!--          <a v-if="step.status === 'complete'" :href="step.href" class="group">-->
  <!--            <span-->
  <!--              aria-hidden="true"-->
  <!--              class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"-->
  <!--            />-->
  <!--            <span-->
  <!--              :class="[-->
  <!--                stepIdx !== 0 ? 'lg:pl-9' : '',-->
  <!--                'px-6 py-5 flex items-start text-sm font-medium'-->
  <!--              ]"-->
  <!--            >-->
  <!--              <span class="flex-shrink-0">-->
  <!--                <span-->
  <!--                  class="w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full"-->
  <!--                >-->
  <!--                  <CheckIcon aria-hidden="true" class="w-6 h-6 text-white" />-->
  <!--                </span>-->
  <!--              </span>-->
  <!--              <span class="mt-0.5 ml-4 min-w-0 flex flex-col">-->
  <!--                <span class="text-xs font-semibold tracking-wide uppercase">{{ step.name }}</span>-->
  <!--                <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>-->
  <!--              </span>-->
  <!--            </span>-->
  <!--          </a>-->
  <!--          <a v-else-if="step.status === 'current'" :href="step.href" aria-current="step">-->
  <!--            <span-->
  <!--              aria-hidden="true"-->
  <!--              class="absolute top-0 left-0 w-1 h-full bg-primary-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"-->
  <!--            />-->
  <!--            <span-->
  <!--              :class="[-->
  <!--                stepIdx !== 0 ? 'lg:pl-9' : '',-->
  <!--                'px-6 py-5 flex items-start text-sm font-medium'-->
  <!--              ]"-->
  <!--            >-->
  <!--              <span class="flex-shrink-0">-->
  <!--                <span-->
  <!--                  class="w-10 h-10 flex items-center justify-center border-2 border-primary-600 rounded-full"-->
  <!--                >-->
  <!--                  <span class="text-primary-600">{{ step.id }}</span>-->
  <!--                </span>-->
  <!--              </span>-->
  <!--              <span class="mt-0.5 ml-4 min-w-0 flex flex-col">-->
  <!--                <span class="text-xs font-semibold text-primary-600 tracking-wide uppercase">{{-->
  <!--                  step.name-->
  <!--                }}</span>-->
  <!--                <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>-->
  <!--              </span>-->
  <!--            </span>-->
  <!--          </a>-->
  <!--          <a v-else :href="step.href" class="group">-->
  <!--            <span-->
  <!--              aria-hidden="true"-->
  <!--              class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"-->
  <!--            />-->
  <!--            <span-->
  <!--              :class="[-->
  <!--                stepIdx !== 0 ? 'lg:pl-9' : '',-->
  <!--                'px-6 py-5 flex items-start text-sm font-medium'-->
  <!--              ]"-->
  <!--            >-->
  <!--              <span class="flex-shrink-0">-->
  <!--                <span-->
  <!--                  class="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full"-->
  <!--                >-->
  <!--                  <span class="text-gray-500">{{ step.id }}</span>-->
  <!--                </span>-->
  <!--              </span>-->
  <!--              <span class="mt-0.5 ml-4 min-w-0 flex flex-col">-->
  <!--                <span class="text-xs font-semibold text-gray-500 tracking-wide uppercase">{{-->
  <!--                  step.name-->
  <!--                }}</span>-->
  <!--                <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>-->
  <!--              </span>-->
  <!--            </span>-->
  <!--          </a>-->
  <!--          <template v-if="stepIdx !== 0">-->
  <!--            &lt;!&ndash; Separator &ndash;&gt;-->
  <!--            <div aria-hidden="true" class="hidden absolute top-0 left-0 w-3 inset-0 lg:block">-->
  <!--              <svg-->
  <!--                class="h-full w-full text-gray-300"-->
  <!--                fill="none"-->
  <!--                preserveAspectRatio="none"-->
  <!--                viewBox="0 0 12 82"-->
  <!--              >-->
  <!--                <path-->
  <!--                  d="M0.5 0V31L10.5 41L0.5 51V82"-->
  <!--                  stroke="currentcolor"-->
  <!--                  vector-effect="non-scaling-stroke"-->
  <!--                />-->
  <!--              </svg>-->
  <!--            </div>-->
  <!--          </template>-->
  <!--        </div>-->
  <!--      </li>-->
  <!--    </ol>-->
  <!--  </nav>-->

  <div v-for="step in steps" class="mb-2 px-6">
    <div v-if="step.fields">
      <ErrorComponent :classes="['mt-4']" v-if="errors.length" :errors="errors" />
      <DynamicForm :fields="step.fields" @handleSubmit="handleFormSubmit" />
    </div>
  </div>
</template>
<script>
  import { CheckIcon } from '@heroicons/vue/solid'
  import DynamicForm from '@/components/DynamicForm.vue'
  import ErrorComponent from '@/components/alerts/ErrorComponent.vue'
  import http from '@/http/index.js'

  export default {
    name: 'CreateAccountForm',
    components: {
      ErrorComponent,
      CheckIcon,
      DynamicForm
    },
    mounted() {
      http.get('/customer/accounts/steps').then(response => {
        this.steps = response.data
      })
    },
    methods: {
      getFieldAttributes(attributes) {
        return attributes.reduce((acc, attr) => {
          acc[attr] = true
          return acc
        }, {})
      },
      handleFormSubmit(formData) {
        http
          .post('/customer/accounts', formData)
          .then(response => {
            this.$router.push('/')
          })
          .catch(errors => {
            this.errors.push(errors.response.data)
          })
      }
    },
    data() {
      return {
        steps: [],
        errors: []
      }
    }
  }
</script>
