import store from '@/store/index.js'
import i18n from '@/utils/i18n.js'
import NotFoundPage from '@/pages/errors/NotFoundPage.vue'
import Dashboard from '@/pages/Dashboard.vue'
import HomePage from '@/pages/HomePage.vue'
import Login from '@/pages/auth/Login.vue'
import ForgotPassword from '@/pages/auth/ForgotPassword.vue'
import ResetPassword from '@/pages/auth/ResetPassword.vue'
import Settings from '@/pages/Settings.vue'
import Profile from '@/pages/Profile.vue'
import Support from '@/pages/Support.vue'
import Subscription from '@/pages/Subscription.vue'
import Calendar from '@/pages/Calendar.vue'
import Privacy from '@/pages/Privacy.vue'
import Download from '@/pages/Download.vue'
import Notifications from '@/pages/Notifications.vue'

const { t } = i18n.global

const MainRoutes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage,
    meta: {
      middleware: 'auth',
      title: 'Non trouvé'
    }
  },
  {
    path: '/',
    component: Dashboard,
    name: t('general.pages.dashboard'),
    meta: {
      middleware: 'auth',
      title: t('general.pages.dashboard')
    },
    children: [
      {
        path: '',
        name: 'HomePage',
        component: HomePage,
        meta: {
          middleware: 'auth',
          title: t('general.pages.dashboard'),
          pageName: t('entities.account', 2),
          breadcrumbs: [
            {
              name: t('general.pages.dashboard'),
              href: '/',
              current: false
            },
            {
              name: t('entities.account', 2),
              href: '/',
              current: true
            }
          ]
        }
      },
      {
        path: '/notifications',
        component: Notifications,
        name: 'Notifications',
        props: true,
        meta: {
          middleware: 'auth',
          title: t('general.pages.notifications'),
          pageName: t('general.pages.notifications'),
          breadcrumbs: [
            {
              name: t('general.pages.dashboard'),
              href: '/',
              current: false
            },
            {
              name: t('general.pages.notifications'),
              href: '/notifications',
              current: true
            }
          ]
        }
      }
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    props: route => ({ message: route.params.message }),
    meta: {
      middleware: 'guest',
      title: t('general.pages.logout')
    }
  },
  {
    path: '/password/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      middleware: 'guest',
      title: `Mot de passe oublié`
    }
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      middleware: 'guest',
      title: `Réinitialiser le mot de passe`
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      store
        .dispatch('auth/logout')
        .then(() => {
          location.reload()
        })
        .catch(error => {
          console.error('Erreur lors de la déconnexion :', error)
          next('/')
        })
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      middleware: 'auth',
      title: 'Paramètres',
      breadcrumbs: [
        {
          name: t('general.pages.dashboard'),
          href: '/',
          current: false
        },
        {
          name: 'Paramètres',
          href: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      middleware: 'auth',
      title: 'Mon profil',
      breadcrumbs: [
        {
          name: t('general.pages.dashboard'),
          href: '/',
          current: false
        },
        {
          name: 'Mon profile',
          href: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/support',
    name: t('general.pages.support'),
    component: Support,
    meta: {
      middleware: 'auth',
      title: t('general.pages.support'),
      breadcrumbs: [
        {
          name: t('general.pages.dashboard'),
          href: '/',
          current: false
        },
        {
          name: t('general.pages.support'),
          href: '/support',
          current: true
        }
      ]
    }
  },
  {
    path: '/subscription/',
    name: 'Subscription',
    children: [
      {
        name: 'SubscriptionDetails',
        path: '',
        component: Subscription,
        meta: {
          middleware: 'auth',
          title: 'Ma souscription',
          breadcrumbs: [
            {
              name: t('general.pages.dashboard'),
              href: '/',
              current: false
            },
            {
              name: 'Souscription',
              href: '/subscription',
              current: true
            }
          ]
        }
      },
      {
        name: 'SubscriptionRenew',
        path: 'renew',
        component: Subscription,
        meta: {
          middleware: 'auth',
          title: 'Renouveler ma souscription',
          breadcrumbs: [
            {
              name: t('general.pages.dashboard'),
              href: '/',
              current: false
            },
            {
              name: 'Souscription',
              href: '/subscription',
              current: true
            },
            {
              name: 'Renouveler',
              href: '/subscription/renew',
              current: true
            }
          ]
        }
      }
    ]
  },
  {
    path: '/calendar',
    component: Calendar,

    meta: {
      middleware: 'auth',
      title: 'Calendrier'
    }
  },
  {
    path: '/download',
    component: Download,
    name: 'Download',
    props: true,
    meta: {
      middleware: 'auth',
      title: t('general.controls.download')
    }
  },
  {
    path: '/privacy',
    component: Privacy
  }
]

export default MainRoutes
