<template>
  <div class="w-full">
    <div class="grid grid-cols-4 gap-4">
      <div v-for="icon in icons" :key="icon.name" class="flex flex-col items-center">
        <component :is="icon.component" class="w-8 h-8" />
        <span class="text-sm mt-2">{{ icon.name }}</span>
      </div>
    </div>
    <hr class="h-4 bg-black my-4" />
    <div class="grid grid-cols-4 gap-4">
      <div v-for="icon in iconsPlain" :key="icon.name" class="flex flex-col items-center">
        <component :is="icon.component" class="w-8 h-8" />
        <span class="text-sm mt-2">{{ icon.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import * as HeroIcons from '@heroicons/vue/outline'
  import * as HeroIconsPlain from '@heroicons/vue/solid'

  export default {
    name: 'IconGallery',
    data() {
      const icons = Object.entries(HeroIcons).map(([name, component]) => ({
        name,
        component
      }))
      const iconsPlain = Object.entries(HeroIconsPlain).map(([name, component]) => ({
        name,
        component
      }))

      return {
        icons,
        iconsPlain
      }
    }
  }
</script>

<style scoped>
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
  }

  .grid div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
