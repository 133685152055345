<template>
  <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
    <div class="sm:col-span-12">
      <dt class="text-sm font-medium text-gray-500">{{ $t('labels.description') }}</dt>
      <dd class="mt-1 max-w-prose text-sm text-gray-900 space-y-5" v-html="dataProp.description" />
    </div>
    <div class="sm:col-span-1">
      <dt class="text-sm font-medium text-gray-500">{{ $t('labels.date_start') }}</dt>
      <dd class="mt-1 text-sm text-gray-900">
        {{ dataProp.start_date }}
      </dd>
    </div>
    <div class="sm:col-span-1">
      <dt class="text-sm font-medium text-gray-500">{{ $t('labels.date_end') }}</dt>
      <dd class="mt-1 text-sm text-gray-900">
        {{ dataProp.end_date }}
      </dd>
    </div>
  </dl>
</template>

<script>
  export default {
    name: 'MetaInfo.vue',
    props: {
      dataProp: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped></style>
