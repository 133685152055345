<template>
  <div class="mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
    <h1 class="text-3xl font-extrabold text-secondary-gray-900">{{ $t('entities.account') }}</h1>

    <form class="mt-6 space-y-8 divide-y divide-y-secondary-gray-200">
      <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
        <div class="sm:col-span-6">
          <h2 class="text-xl font-medium text-secondary-gray-900">
            {{ $t('general.pages.profile') }}
          </h2>
          <p class="mt-1 text-sm text-secondary-gray-500">
            {{ $t('certainesDeCesInformationsSerontAffichsPubliquemen') }}
          </p>
        </div>

        <div class="sm:col-span-3">
          <label for="first-name" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('attendee.fields.first_name') }}
          </label>
          <input
            type="text"
            v-model="$user.first_name"
            name="first-name"
            id="first-name"
            autocomplete="given-name"
            class="mt-1 block w-full border-secondary-gray-300 rounded-md shadow-sm text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
          />
        </div>

        <div class="sm:col-span-3">
          <label for="last-name" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('attendee.fields.last_name') }}
          </label>
          <input
            type="text"
            v-model="$user.last_name"
            name="last-name"
            id="last-name"
            autocomplete="family-name"
            class="mt-1 block w-full border-secondary-gray-300 rounded-md shadow-sm text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
          />
        </div>

        <!--        <div class="sm:col-span-6">-->
        <!--          <label for="username" class="block text-sm font-medium text-secondary-gray-900"> Username </label>-->
        <!--          <div class="mt-1 flex rounded-md shadow-sm">-->
        <!--            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-secondary-gray-300 bg-secondary-gray-50 text-secondary-gray-500 sm:text-sm"> workcation.com/ </span>-->
        <!--            <input type="text" name="username" id="username" autocomplete="username" value="lisamarie" class="flex-1 block w-full min-w-0 border-secondary-gray-300 rounded-none rounded-r-md text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500" />-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="sm:col-span-6">
          <label for="photo" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('photo') }}
          </label>
          <div class="mt-1 flex items-center">
            <img
              class="inline-block h-12 w-12 rounded-full"
              src="@/assets/Obi-Wan-icon.png"
              :alt="$user.full_name"
            />
            <div class="ml-4 flex">
              <div
                class="relative bg-white py-2 px-3 border border-secondary-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-secondary-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-secondary-gray-50 focus-within:ring-secondary-500"
              >
                <label
                  for="user-photo"
                  class="relative text-sm font-medium text-secondary-gray-900 pointer-events-none"
                >
                  <span>{{ $t('general.controls.edit') }}</span>
                  <span class="sr-only"> {{ $t('userPhoto') }}</span>
                </label>
                <input
                  id="user-photo"
                  name="user-photo"
                  type="file"
                  class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                />
              </div>
              <button
                type="button"
                class="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-secondary-gray-900 hover:text-secondary-gray-700 focus:outline-none focus:border-secondary-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-gray-50 focus:ring-secondary-500"
              >
                {{ $t('general.controls.delete') }}
              </button>
            </div>
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="description" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('bio') }}
          </label>
          <div class="mt-1">
            <textarea
              id="description"
              name="description"
              rows="4"
              class="block w-full border border-secondary-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
            />
          </div>
          <p class="mt-3 text-sm text-secondary-gray-500">
            {{ $t('brefDescriptionDeVotreProfil') }}
          </p>
        </div>

        <div class="sm:col-span-6">
          <label for="url" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('url') }}
          </label>
          <input
            type="text"
            name="url"
            id="url"
            class="mt-1 block w-full border-secondary-gray-300 rounded-md shadow-sm text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
          />
        </div>
      </div>

      <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
        <div class="sm:col-span-6">
          <h2 class="text-xl font-medium text-secondary-gray-900">
            {{ $t('informationsPersonnelles') }}
          </h2>
          <p class="mt-1 text-sm text-secondary-gray-500">
            {{ $t('cesInformationsSerontAffichesPubliquementAlorsFait') }}
          </p>
        </div>

        <div class="sm:col-span-3">
          <label for="email-address" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('attendee.fields.email') }}
          </label>
          <input
            type="text"
            name="email-address"
            v-model="$user.email"
            id="email-address"
            autocomplete="email"
            class="mt-1 block w-full border-secondary-gray-300 rounded-md shadow-sm text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
          />
        </div>

        <div class="sm:col-span-3">
          <label for="phone-number" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('attendee.fields.phone_number') }}
          </label>
          <input
            type="text"
            name="phone-number"
            v-model="$user.phone"
            id="phone-number"
            autocomplete="tel"
            class="mt-1 block w-full border-secondary-gray-300 rounded-md shadow-sm text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
          />
        </div>

        <div class="sm:col-span-3">
          <label for="country" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('country') }}
          </label>
          <select
            id="country"
            name="country"
            autocomplete="country-name"
            class="mt-1 block w-full border-secondary-gray-300 rounded-md shadow-sm text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
          >
            <option />
            <option>{{ $t('unitedStates') }}</option>
            <option>{{ $t('canada') }}</option>
            <option>{{ $t('mexico') }}</option>
          </select>
        </div>

        <div class="sm:col-span-3">
          <label for="language" class="block text-sm font-medium text-secondary-gray-900">
            {{ $t('language') }}
          </label>
          <input
            type="text"
            name="language"
            id="language"
            class="mt-1 block w-full border-secondary-gray-300 rounded-md shadow-sm text-secondary-gray-900 sm:text-sm focus:ring-secondary-500 focus:border-secondary-500"
          />
        </div>

        <p class="text-sm text-secondary-gray-500 sm:col-span-6">
          {{ $t('ceCompteATCrLe') }}
          <time :datetime="$user.registered_at">{{ $user.registered_at }}</time
          >.
        </p>
      </div>

      <div class="pt-8 flex justify-end">
        <button
          type="button"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-secondary-gray-900 hover:bg-secondary-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
        >
          {{ $t('general.controls.cancel') }}
        </button>
        <button
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
        >
          {{ $t('general.controls.store') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'Account.vue'
  }
</script>

<style scoped></style>
