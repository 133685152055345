export default {
  namespaced: true,
  state: {
    authenticated: false,
    token: '',
    user: {}
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_USER(state, value) {
      state.user = value
    },
    SET_TOKEN(state, value) {
      state.token = value
    }
  },
  actions: {
    login({ commit }, data) {
      commit('SET_USER', data.user)
      commit('SET_TOKEN', data.token)
      commit('SET_AUTHENTICATED', true)
      location.assign('/')
    },
    logout({ commit }) {
      commit('SET_USER', {})
      commit('SET_TOKEN', '')
      commit('SET_AUTHENTICATED', false)
    }
  }
}
