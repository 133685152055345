<template>
  <div class="rounded-md p-4 w-full mx-auto" v-if="loading">
    <div class="animate-pulse flex space-x-4">
      <div class="rounded-full bg-gray-400 h-10 w-10"></div>
      <div class="flex-1 space-y-6 py-1">
        <div class="h-2 bg-gray-400 rounded"></div>
        <div class="space-y-3">
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-gray-400 rounded col-span-2"></div>
            <div class="h-2 bg-gray-400 rounded col-span-1"></div>
          </div>
          <div class="h-2 bg-slate-400 rounded"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{ $t('entities.activity') }}</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the users in your account including their name, title, email and role.
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <GenericGroupButton
          :mainActionLabel="$t('general.entityAction', { entity: $t('entities.activity', 1).toLowerCase(), control: $t('general.controls.create') })"
          :items='createMenuItems' @main-action-click="onMainAction" @item-action-click="onItemAction">
        </GenericGroupButton>
      </div>
    </div>
    <div class="mt-8 flex flex-col" v-if="activities.length">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div v-if="selectedActivities.length > 0"
              class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
              <button type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                Bulk edit
              </button>
              <button type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                Delete all
              </button>
            </div>
            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                      :checked="indeterminate || selectedActivities.length === activities.length"
                      :indeterminate="indeterminate" @change="
    selectedActivities = $event.target.checked ? activities.map(p => p.id) : []
    " />
                  </th>
                  <th scope="col" class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                    Titre
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Début
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Fin
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">{{ $t('general.controls.edit') }}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="activity in activities" :key="activity.id"
                  :class="[selectedActivities.includes(activity.id) && 'bg-gray-50']">
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <div v-if="selectedActivities.includes(activity.id)"
                      class="absolute inset-y-0 left-0 w-0.5 bg-primary-600"></div>
                    <input type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                      :value="activity.id" v-model="selectedActivities" />
                  </td>
                  <td :class="[
    'whitespace-nowrap py-4 pr-3 text-sm font-medium',
    selectedActivities.includes(activity.id)
      ? 'text-primary-600'
      : 'text-gray-900'
  ]">
                    <div class="flex items-center gap-2 justify-start">
                      <div>
                        <i class="fal fa-chevron-right cursor-pointer" @click="toggleChildren(activity)"></i>
                      </div>
                      <div>
                        {{ activity.title }}
                        <div class="text-gray-500">{{ activity.description }}</div>
                      </div>
                    </div>

                    <div v-if="expandedActivity === activity" v-for="childActivity in activity.activities"
                      :key="childActivity.id">
                      <div class="flex gap-2">
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800 gap-2">
                          <i class="fasl fa-calendar-alt"></i> {{ childActivity.title }}
                        </span>
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 gap-2">
                          <i class="fasl fa-users"></i> {{ childActivity.capacity }}
                        </span>
                        <span
                          class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-amber-100 text-amber-800 gap-2">
                          <i class="fasl fa-clock"></i> {{ childActivity.start_datetime }}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ activity.type }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ activity.start_datetime }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ activity.end_datetime }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a href="#" class="text-primary-600 hover:text-primary-900">{{ $t('general.controls.edit')
                      }}<span class="sr-only">, {{ activity.title }}</span></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <EmptyState :entity="$t('entities.activity', 3).toLowerCase()" icon="calendar-xmark" v-else />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import http from '@/http/index.js'
import { useRoute } from 'vue-router'
import EmptyState from '@/components/hints/EmptyState.vue'
import GenericButton from '@/components/buttons/GenericButton.vue'
import GenericGroupButton from '@/components/buttons/GenericGroupButton.vue'
import CreateSession from "@/components/events/sheduling/CreateSession.vue"
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  components: { GenericGroupButton, GenericButton, EmptyState },
  setup() {
    const { t } = useI18n();
    const activities = ref([])
    const loading = ref(true)
    const selectedActivities = ref([])
    const expandedActivity = ref(null)
    const checked = ref(false)
    const store = useStore();
    const createMenuItems = [
      { name: t('general.entityAction', { entity: t('entities.activities.session', 1).toLowerCase(), control: t('general.controls.create') }), action: 'session', icon: 'fasl fa-calendar-plus' },
      { name: t('general.entityAction', { entity: t('entities.activities.workshop', 1).toLowerCase(), control: t('general.controls.create') }), action: 'workshop', icon: 'fasl fa-calendar-plus' }
    ]

    const indeterminate = computed(
      () =>
        selectedActivities.value.length > 0 && selectedActivities.value.length < activities.length
    )
    const route = useRoute()
    const toggleChildren = activity => {
      expandedActivity.value = expandedActivity.value === activity ? null : activity
    }

    http.get(`/customer/events/${route.params.id}/planning`).then(response => {
      activities.value = response.data
      loading.value = false
    })

    const onMainAction = (action) => {
      alert('activity');
    }
    const onItemAction = (action) => {
      if (action === 'session') {
        store.commit('drawer/setDynamicComponent', CreateSession)
        store.commit('drawer/setDrawerTitle', 'Créer une nouvelle session')
        store.commit('drawer/setDrawerDescription', 'Une session c\'est l\'unité principale de temps dans un événement.')
        store.commit('drawer/openDrawer')
      }
    }

    return {
      checked,
      indeterminate,
      loading,
      activities,
      expandedActivity,
      createMenuItems,
      toggleChildren,
      onMainAction,
      onItemAction,
      selectedActivities
    }
  }
}
</script>
