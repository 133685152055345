<template>
  <div class="flex w-full justify-center items-center">
    <div class="flex justify-center gap-6">
      <h3 class="text-6xl font-bold text-primary-600 border-r pr-6">404</h3>
      <span class="self-center font-bold"> Non trouvé </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFoundPage'
  }
</script>

<style scoped></style>
