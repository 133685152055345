<template>
  <div
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="shown"
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <component
                  :is="iconComponent"
                  :class="toastClass"
                  class="h-6 w-6"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">{{ title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ body }}</p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="hideToast"
                  class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div class="relative w-full h-1 bg-gray-200">
            <div
              class="absolute h-1 bg-gradient-to-r from-secondary-700 to-primary-700"
              :style="{ width: progressWidth }"
            ></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { CheckCircleIcon, XIcon } from '@heroicons/vue/solid'
  import {
    ExclamationIcon,
    InformationCircleIcon,
    ExclamationCircleIcon
  } from '@heroicons/vue/solid'

  export default {
    name: 'Toast',
    components: {
      CheckCircleIcon,
      XIcon,
      ExclamationIcon,
      InformationCircleIcon,
      ExclamationCircleIcon
    },
    watch: {
      shown(newVal) {
        if (newVal) {
          this.startProgress()
          setTimeout(() => {
            this.hideToast()
          }, 5000)
        }
      }
    },
    data() {
      return {
        progressWidth: '0%'
      }
    },
    computed: {
      ...mapState('toast', ['shown', 'type', 'title', 'body']),
      toastClass() {
        switch (this.type) {
          case 'success':
            return 'text-mantis-500'
          case 'danger':
            return 'text-red-500'
          case 'info':
            return 'text-primary-500'
          case 'warning':
            return 'text-yellow-500'
          default:
            return 'text-white'
        }
      },
      iconComponent() {
        switch (this.type) {
          case 'success':
            return CheckCircleIcon
          case 'danger':
            return ExclamationCircleIcon
          case 'info':
            return InformationCircleIcon
          case 'warning':
            return ExclamationIcon
          default:
            return CheckCircleIcon
        }
      }
    },
    methods: {
      ...mapActions('toast', ['hideToast']),

      startProgress() {
        this.progressWidth = '0%'
        let width = 0
        const increment = 0.1 // Augmentation de 0.1%
        const intervalTime = 4 // Toutes les 5ms
        const interval = setInterval(() => {
          width += increment
          this.progressWidth = `${width}%`
          if (width >= 100) {
            clearInterval(interval)
          }
        }, intervalTime)
      }
    }
  }
</script>

<style scoped>
  /* Add any additional styles if needed */
</style>
