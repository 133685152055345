<template>
  <button type="button" :class="['button-primary', customClasses]">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: 'ButtonPrimary',
    props: {
      customClasses: {
        type: String,
        default: ''
      }
    }
  }
</script>
