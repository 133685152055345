<script>
  import { markRaw, ref, watchEffect } from 'vue'
  import TagInput from '@/components/forms/TagInput.vue'
  import store from '@/store/index.js'
  import GenericButton from '@/components/buttons/GenericButton.vue'

  export default {
    components: { GenericButton, TagInput },
    setup(props, { emit }) {
      const max_attendees = ref(null)
      const registration_open = ref(false)
      let chosenFields = markRaw([])
      const fields = ref(['first_name', 'last_name', 'phone_number', 'address', 'email'])

      const nextStep = () => {
        emit('goToStep', 4)
      }

      const setSelectedFields = fields => {
        chosenFields = fields.value
      }

      const localEvent = store.state.events.newEvent

      if (localEvent.settings) {
        max_attendees.value = localEvent.settings.max_attendees
        registration_open.value = localEvent.settings.registration_open
      }

      watchEffect(() => {
        if (localEvent.settings) {
          localEvent.settings.max_attendees = max_attendees.value
          localEvent.settings.registration_open = registration_open.value
        } else {
          localEvent.settings = {
            max_attendees: max_attendees.value,
            registration_open: registration_open.value
          }
        }

        store.dispatch('events/setEvent', localEvent)
      })

      return {
        max_attendees,
        registration_open,
        chosenFields,
        fields,
        setSelectedFields,
        nextStep,
        localEvent
      }
    }
  }
</script>

<template>
  <div class="space-y-6">
    <div>
      <h1 class="text-lg leading-6 font-medium text-gray-900">Inscriptions</h1>
      <p class="mt-1 text-sm text-gray-500">
        Gérez la liste des participants et configurez les options d'inscription.
      </p>
    </div>
    <div class="w-1/4">
      <label for="name" class="block text-sm font-medium text-gray-700">
        Nombre d'inscrits (PAX)</label
      >
      <div class="mt-1">
        <input
          type="number"
          name="max_attendees"
          id="max_attendees"
          min="0"
          v-model="max_attendees"
          class="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>

    <fieldset class="space-y-5">
      <div class="relative flex items-start">
        <div class="flex items-center h-5">
          <input
            id="registration_open"
            v-model="registration_open"
            aria-describedby="comments-description"
            name="registration_open"
            type="checkbox"
            class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
          />
        </div>
        <div class="ml-3 text-sm">
          <label for="registration_open" class="font-medium text-gray-700"
            >Inscriptions ouvertes</label
          >
        </div>
      </div>
    </fieldset>

    <fieldset class="w-1/4">
      <legend class="block text-sm font-medium text-gray-700">Champs requis a l'inscription</legend>

      <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
        <TagInput :choices="fields" @updateTagInputContent="setSelectedFields" />
      </div>
    </fieldset>
  </div>

  <GenericButton type="clear" class="mt-4" @click.prevent="nextStep">
    {{ $t('pagination.next') }}
  </GenericButton>
</template>

<style scoped></style>
