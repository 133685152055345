<template>
  <span class="relative z-0 inline-flex shadow-sm rounded-md">
    <button
      type="button"
      @click="handleMainAction"
      class="relative inline-flex items-center justify-center gap-2 px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
    >
      <i v-if="icon" :class="[`${icon}`]"></i>
      {{ mainActionLabel }}
    </button>
    <Menu as="span" class="-ml-px relative block">
      <MenuButton
        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
      >
        <span class="sr-only">Open options</span>
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <MenuItem v-for="(item, index) in items" :key="item.name" v-slot="{ active }">
              <button
                @click="handleItemAction(item)"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full text-left px-4 py-2 text-sm',
                  'flex items-center gap-2'
                ]"
              >
                <i v-if="item.icon" :class="[item.icon, 'text-primary-700']"></i>
                {{ item.name }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </span>
</template>

<script>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/solid'
  import { ref, toRefs } from 'vue'

  export default {
    name: 'GenericGroupButton',
    components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      ChevronDownIcon
    },
    props: {
      mainActionLabel: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        default: () => []
      },
      icon: {
        type: String,
        default: null
      }
    },
    setup(props, { emit }) {
      const handleMainAction = () => {
        emit('main-action-click')
      }

      const handleItemAction = item => {
        emit('item-action-click', item.action)
      }

      return {
        handleMainAction,
        handleItemAction
      }
    }
  }
</script>

<style scoped>
  /* Add your styles here if necessary */
</style>
