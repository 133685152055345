import ShowEvent from '@/pages/events/ShowEvent.vue'
import CreateEvent from '@/pages/events/CreateEvent.vue'
import store from '@/store/index.js'
import i18n from '@/utils/i18n.js'

const { t } = i18n.global

const EventRoutes = [
  {
    path: '/events',
    name: 'EventsIndex',
    meta: {
      middleware: 'auth',
      title: 'Nouvel événement'
    },
    redirect: to => {
      const slug = store.state.accounts.activeAccount?.slug
      return `/accounts/${slug}/events`
    }
  },
  {
    path: '/events/create/:accountId?',
    name: 'CreateEvent',
    component: CreateEvent,
    meta: {
      middleware: 'auth',
      title: 'Nouvel événement',
      breadcrumbs: [
        {
          name: t('general.pages.dashboard'),
          href: '/',
          current: false
        },
        {
          name: t('entities.event', 2),
          href: '/events',
          current: false
        },
        {
          name: "Assistant création d'événement",
          href: '#',
          current: true
        }
      ]
    }
  },
  {
    path: '/events/:id',
    name: 'ShowEvent',
    component: ShowEvent,
    meta: {
      middleware: 'auth',
      title: 'Détails événement',
      breadcrumbs: [
        {
          name: t('general.pages.dashboard'),
          href: '/',
          current: false
        },
        {
          name: t('entities.event', 2),
          href: '/events',
          current: false
        },
        {
          name: t('events.menu.file'),
          href: '#',
          current: true
        }
      ]
    }
  }
]

export default EventRoutes
