<template>
  <div class="flex-1 min-w-0 bg-white xl:flex">
    <!-- Account profile -->
    <div
      :class="{
        'xl:border-gray-200 xl:border-r': $store.state.appSettings.direction === 'ltr',
        'xl:border-gray-200 xl:border-l': $store.state.appSettings.direction === 'rtl'
      }"
      class="xl:flex-shrink-0 xl:w-64 bg-white"
    >
      <div class="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8">
        <div class="flex items-center justify-between">
          <div class="flex-1 space-y-8">
            <div
              class="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-8"
            >
              <!-- Profile -->
              <div class="flex items-center space-x-3">
                <div class="flex-shrink-0 h-12 w-12">
                  <img class="h-12 w-12 rounded-full" src="@/assets/Obi-Wan-icon.png" alt="" />
                </div>
                <div class="space-y-1">
                  <div class="text-sm font-medium text-gray-900">{{ $user.full_name }}</div>
                  <a href="#" class="group flex items-center space-x-1">
                    <MailIcon class="opacity-40 h-5 w-5" />
                    <span class="text-sm text-gray-500 group-hover:text-gray-900 font-medium">{{
                      trimEmail($user.email)
                    }}</span>
                  </a>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row xl:flex-col">
                <router-link
                  to="/accounts/create"
                  class="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gradient-to-r from-primary-700 to-secondary-700 hover:from-secondary-700 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 xl:w-full transition delay-150"
                >
                  <p>
                    {{
                      $t('general.entityAction', {
                        control: $t('general.controls.create'),
                        entity: $t('entities.account')
                      })
                    }}
                  </p>
                </router-link>
                <button
                  type="button"
                  class="mt-3 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-200 cursor-not-allowed bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 xl:ml-0 xl:mt-3 xl:w-full"
                >
                  <LockClosedIcon class="w-6 h-5 mr-2" />

                  {{ $t('general.controls.invite') }}
                </button>
              </div>
            </div>
            <div
              class="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6"
            >
              <div class="flex items-center space-x-2">
                <BadgeCheckIcon class="h-5 w-5 text-mantis-500" aria-hidden="true" />
                <span class="text-sm text-gray-500 font-medium"
                  >Pack {{ $user.subscription.pack.name }}</span
                >
              </div>
              <div class="flex items-center space-x-2">
                <CollectionIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                <Transition name="count">
                  <span
                    class="text-sm text-gray-500 font-medium"
                    v-text="
                      $t('general.outOf', {
                        count: $store.state.accounts.accounts.length,
                        entity: $t(
                          'entities.account',
                          $store.state.accounts.accounts.length
                        ).toLowerCase(),
                        total: $user.subscription.pack.max_accounts
                      })
                    "
                  ></span>
                </Transition>
              </div>

              <div class="flex items-center space-x-2">
                <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span class="text-sm text-gray-500 font-medium"
                  >{{ $t('labels.active_until') }}
                  {{ formatDate($user.subscription.date_end) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white lg:min-w-0 lg:flex-1">
      <div
        class="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0"
      >
        <div class="flex items-center gap-2">
          <h1 class="flex-1 text-lg font-medium">{{ $route.meta.pageName }}</h1>
          <div class="ml-6 bg-gray-100 p-0.5 rounded-lg flex items-center z-50">
            <button
              type="button"
              class="p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            >
              <ViewListIcon class="h-5 w-5" aria-hidden="true" />
              <span class="sr-only">Use list view</span>
            </button>
            <button
              type="button"
              class="ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            >
              <ViewGridIcon class="h-5 w-5" aria-hidden="true" />
              <span class="sr-only">Use grid view</span>
            </button>
          </div>
          <div class="relative">
  <Menu>
    <MenuButton
    class="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 gap-2"
  >
    <SortAscendingIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
    {{ $t('general.controls.sort') }}
    <ChevronDownIcon class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
  </MenuButton>
  <MenuItems
    class="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
  >
    <div class="py-1">
      <MenuItem v-slot="{ active }">
        <a
          href="#"
          @click="sortBy('name')"
          :class="[
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'block px-4 py-2 text-sm'
          ]"
          >{{ $t('labels.name') }}</a
        >
      </MenuItem>
      <MenuItem v-slot="{ active }">
        <a
          href="#"
          :class="[
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'block px-4 py-2 text-sm'
          ]"
          @click="sortBy('created_at')"
          >{{ $t('labels.created_at') }}</a
        >
      </MenuItem>
      <MenuItem v-slot="{ active }">
        <a
          href="#"
          :class="[
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'block px-4 py-2 text-sm'
          ]"
          @click="sortBy('date_start')"
          >{{ $t('labels.date_start') }}</a
        >
      </MenuItem>
    </div>
  </MenuItems>
  </Menu>
</div>


          <GenericButton
            type="outline-secondary"
            icon="far fa-plus"
            customClasses="flex flex-row items-center gap-1"
            @click="
              $router.push({
                name: 'CreateEvent',
                query: { account: $store.state.accounts.activeAccount.slug }
              })
            "
          >
            {{ $t('general.controls.create') }}
          </GenericButton>
        </div>
      </div>
      <router-view />
    </div>
    <ActivityFeed />
  </div>
</template>

<script>
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  } from '@headlessui/vue'
  import {
    BadgeCheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CollectionIcon,
    SearchIcon,
    SortAscendingIcon,
    StarIcon,
    UserCircleIcon,
    MailIcon,
    LockClosedIcon,
    ViewListIcon,
    ViewGridIcon
  } from '@heroicons/vue/solid'
  import moment from 'moment'
  import { MenuAlt1Icon, XIcon, CalendarIcon } from '@heroicons/vue/outline'
  import ActivityFeed from '../components/ActivityFeed.vue'
  import store from '../store/index.js'
  import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
  import GenericButton from '@/components/buttons/GenericButton.vue'

  export default {
    name: 'Dashboard',
    components: {
      ButtonPrimary,
      ActivityFeed,
      Disclosure,
      LockClosedIcon,
      DisclosureButton,
      DisclosurePanel,
      GenericButton,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      BadgeCheckIcon,
      ChevronDownIcon,
      ChevronRightIcon,
      CollectionIcon,
      MenuAlt1Icon,
      SearchIcon,
      SortAscendingIcon,
      StarIcon,
      XIcon,
      UserCircleIcon,
      MailIcon,
      CalendarIcon,
      ViewListIcon,
      ViewGridIcon
    },
    beforeCreate() {
      if (this.$user === undefined) {
        this.$user = store.state.auth.user
      }
    },
    methods: {
      sortBy(filter) {
        this.sortOrder = filter
        console.log(this.sortOrder);
      },
      trimEmail(s) {
        var i = s.indexOf('@')
        var startIndex = (i * 0.2) | 0
        var endIndex = (i * 0.9) | 0
        const email =
          s.slice(0, startIndex) +
          s.slice(startIndex, endIndex).replace(/./g, '*') +
          s.slice(endIndex)

        return email.split('@')[0]
      },

      formatDate(date) {
        return moment(date).format('DD/MM/Y')
      }
    },
    data() {
      return {
        sortOrder: 'date'
      }
    }
  }
</script>

<style>
  .list-move,
  /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  /* ensure leaving items are taken out of layout flow so that moving
 animations can be calculated correctly. */
  .list-leave-active {
    position: absolute;
  }

  .count-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .count-enter-from,
  .count-leave-to {
    opacity: 0;
  }
</style>
