import Icons from '@/pages/dev/Icons.vue'

const DevRoutes = [
  {
    path: '/dev/icons',
    name: 'Icons',
    meta: {
      middleware: 'auth',
      title: 'Nouvel événement'
    },
    component: Icons
  }
]

export default DevRoutes
