<template>
    <div v-if="visible" class="w-full p-4 border-b border-yellow-400 bg-yellow-50 text-yellow-800 relative">
      <div class="flex items-center">
        <i class="fas fa-exclamation-triangle text-yellow-600 mr-2"></i>
        <h2 class="text-lg font-semibold">Dev. mode</h2>
      </div>
      <p class="mt-2 text-sm">
        Veuillez noter qu'il s'agit d'une version bêta de l'application. Le développement est encore en cours, et
        certaines fonctionnalités peuvent être indisponibles ou incomplètes. Il est fortement conseillé de ne pas
        utiliser cette application en production.
      </p>
      <button
        @click="hideComponent"
        class="absolute top-2 right-2 text-yellow-600 hover:text-yellow-800 transition"
        aria-label="Close"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  
  const visible = ref(true)
  
  const hideComponent = () => {
    visible.value = false
  }
  </script>
  
  <style scoped>
  /* Optionally, add custom styles */
  </style>
  