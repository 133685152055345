<template>
  <div>
    <div class="relative">
      <input
        v-model="inputText"
        @keydown.enter.prevent="addTag"
        type="text"
        :class="inputClasses ? inputClasses : 'inputClasses'"
        placeholder="Ajouter des tags..."
      />
      <div
        v-if="filteredChoices.length > 0 && inputText !== ''"
        class="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md"
      >
        <ul class="py-1">
          <li
            v-for="(choice, index) in filteredChoices"
            :key="index"
            @click="addTag(choice)"
            class="cursor-pointer select-none relative py-2 pl-3 pr-9 text-sm"
          >
            {{ choice }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="tags.length > 0" class="mt-2 space-x-2">
      <span
        v-for="(tag, index) in tags"
        :key="index"
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-primary-100 text-primary-800"
      >
        {{ tag }}
        <button
          @click="removeTag(index)"
          type="button"
          class="flex-shrink-0 ml-1.5 inline-flex text-primary-500"
        >
          <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      </span>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, defineEmits } from 'vue'

  const inputText = ref('')
  const tags = ref([])
  const choicesProp = defineProps({
    choices: Array,
    inputClasses: String
  })

  const emit = defineEmits(['updateTagInputContent'])

  const filteredChoices = computed(() => {
    return choicesProp.choices.filter(choice =>
      choice.toLowerCase().includes(inputText.value.toLowerCase())
    )
  })

  const addTag = tag => {
    if (tag && !tags.value.includes(tag)) {
      tags.value.push(tag)
      inputText.value = ''
    }
    emit('updateTagInputContent', tags)
  }

  const removeTag = index => {
    tags.value.splice(index, 1)
    emit('updateTagInputContent', tags)
  }

  const handleInput = () => {}
</script>

<style scoped>
  .inputClasses {
    @apply block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md;
  }
</style>
