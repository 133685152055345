<template>
  <div class="flex flex-col justify-between h-5/6">
    <div id="form">
      <label for="poll-title" class="block text-sm font-medium text-gray-700">
        Titre du sondage
      </label>
      <div class="mt-1">
        <input
          type="text"
          id="poll-title"
          v-model="poll.name"
          class="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      <draggable
        v-model="poll.questions"
        group="questions"
        handle=".drag-handle"
        @end="updateOrder"
        itemKey="id"
        class="mt-4 space-y-4"
      >
        <template #item="{ element: question, index }">
          <div :key="index" class="border-2 border-dashed border-gray-600 p-4 rounded">
            <div class="flex items-start">
              <div class="drag-handle cursor-move mr-2">
                <i class="fa-solid fa-up-down-left-right text-gray-500"></i>
              </div>
              <div class="flex-1">
                <label :for="'question-' + index" class="block text-sm font-medium text-gray-700">
                  {{ question.label }}
                </label>
                <div class="mt-1">
                  <template v-if="question.type === 'radio' || question.type === 'checkbox'">
                    <input
                      type="text"
                      :id="'question-' + index"
                      v-model="question.text"
                      placeholder="Intitulé de la question"
                      class="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </template>
                  <template v-else-if="question.type === 'textarea'">
                    <textarea
                      :id="'question-' + index"
                      v-model="question.text"
                      class="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                    ></textarea>
                  </template>
                </div>
              </div>
              <div class="ml-2 flex self-center">
                <button
                  type="button"
                  @click="removeQuestion(index)"
                  class="text-gray-500 hover:text-red-500 focus:outline-none"
                >
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div v-if="question.type === 'checkbox' || question.type === 'radio'" class="mt-1">
              <template v-for="(option, optionIndex) in question.options" :key="optionIndex">
                <div class="relative">
                  <input
                    type="text"
                    v-model="option.text"
                    :placeholder="'Choix N°' + (optionIndex + 1)"
                    class="block w-full mt-1 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md pr-10"
                  />
                  <button
                    type="button"
                    @click="removeOption(index, optionIndex)"
                    class="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-red-500 focus:outline-none"
                  >
                    <XCircleIcon class="h-5 w-5" />
                  </button>
                </div>
              </template>
              <button
                type="button"
                @click="addOption(index)"
                class="mt-2 text-sm text-gray-500 hover:text-blue-500 focus:outline-none"
              >
                + Ajouter une option
              </button>
            </div>
          </div>
        </template>
      </draggable>

      <div class="mt-4 flex flex-col w-fit gap-2">
        <button
          type="button"
          @click="addQuestion('radio')"
          class="button-clear flex gap-2 items-center justify-left"
        >
          <i class="fal fa-list-radio text-xl text-secondary-500"></i>
          Ajouter question Radio
        </button>
        <button
          type="button"
          @click="addQuestion('checkbox')"
          class="button-clear flex gap-2 items-center justify-left"
        >
          <i class="fal fa-check-square text-xl text-secondary-500"></i>
          Ajouter question Checkbox
        </button>
        <button
          type="button"
          @click="addQuestion('textarea')"
          class="button-clear flex gap-2 items-center justify-left"
        >
          <i class="fal fa-text text-xl text-secondary-500"></i>
          Ajouter question Textarea
        </button>
      </div>
    </div>
    <div class="self-end justify-self-end">
      <ButtonPrimary>Enregistrer</ButtonPrimary>
    </div>
  </div>
</template>

<script>
  import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
  import { XCircleIcon } from '@heroicons/vue/outline' // Import the icon from Heroicons
  import draggable from 'vuedraggable'

  export default {
    name: 'CreatePollsForm',
    components: {
      ButtonPrimary,
      XCircleIcon,
      draggable
    },
    data() {
      return {
        poll: {
          name: null,
          questions: []
        }
      }
    },
    methods: {
      addQuestion(type) {
        let label = ''
        if (type === 'radio') {
          label = 'Question à choix unique'
        } else if (type === 'checkbox') {
          label = 'Question à choix multiples'
        } else if (type === 'textarea') {
          label = 'Question ouverte'
        }

        this.poll.questions.push({
          type: type,
          label: label,
          text: '',
          options: type === 'radio' || type === 'checkbox' ? [{ text: '' }] : [],
          order: this.poll.questions.length + 1
        })
      },
      removeQuestion(index) {
        this.poll.questions.splice(index, 1)
        this.updateOrder()
      },
      addOption(questionIndex) {
        this.poll.questions[questionIndex].options.push({ text: '' })
      },
      removeOption(questionIndex, optionIndex) {
        this.poll.questions[questionIndex].options.splice(optionIndex, 1)
      },
      updateOrder() {
        this.poll.questions.forEach((question, index) => {
          question.order = index + 1
        })
      }
    }
  }
</script>

<style scoped>
  /* Add your styles here */
  .drag-handle {
    cursor: move;
  }
</style>
