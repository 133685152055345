import { createApp } from 'vue'
import './style.css'
import './assets/app.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/ar-ma'
import { createI18n } from 'vue-i18n'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import en from './locales/en.json'
import fr from './locales/fr.json'
import ar from '@/locales/ar.json'
import { tailwindColors } from '../tailwind-colors'

let defaultLocale

if (localStorage.getItem('locale')) {
  defaultLocale = localStorage.getItem('locale')
} else {
  defaultLocale = 'en'
}

const messages = {
  en,
  fr,
  ar
}

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
})

moment.locale(defaultLocale)

const app = createApp(App)

const progressOptions = {
  color: tailwindColors['yellow']['700'],
  failedColor: tailwindColors['red']['700'],
  thickness: '5px',
  transition: {
    speed: '0.8s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: false,
  location: 'top',
  inverse: true
}

app.use(i18n)
app.use(store)
app.use(router)
app.use(VueProgressBar, progressOptions)

app.config.globalProperties.$moment = moment

app.config.globalProperties.$filters = {
  timeAgo(date) {
    return moment(date).fromNow()
  },
  format(date, format) {
    return moment(date).format(format)
  },
  diffInDays(date) {
    return moment(date).diff(moment(), 'days')
  }
}

// Si l'utilisateur est authentifié, configurez les propriétés globales
if (store.state.auth.authenticated) {
  app.config.globalProperties.$user = store.state.auth.user
  // Décommentez et configurez Echo et Pusher si nécessaire
  // window.Pusher = Pusher;
  // window.Echo = new Echo({
  //   broadcaster: 'pusher',
  //   key: 'ee',
  //   cluster: 'ee',
  //   wsHost: '127.0.0.1',
  //   wsPort: 6001,
  //   forceTLS: false,
  // });
}

app.mount('#app')
