<template>
  <div class="w-full mt-2 bg-white p-5">
    <div>
      <h3 class="text-lg font-medium leading-6 text-gray-900">Ma souscription</h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">Détails du plan et mise a niveau.</p>
    </div>
    <div class="mt-5 border-gray-200">
      <div
        class="bg-amber-100 rounded p-3 text-amber-700 flex justify-start gap-2 items-center"
        v-if="showRenewAlert"
      >
        <InformationCircleIcon class="w-6 h-6 color-amber-300" />
        Votre souscription arrive a son terme le
        <strong>{{ $filters.format($user.subscription.date_end, 'DD/MM/YYYY') }}</strong> cliquez
        <router-link to="/subscription/renew" class="font-bold">ici</router-link>
        pour renouveler .
      </div>
      <dl class="divide-y divide-gray-200">
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt class="text-sm font-medium text-gray-500">Mode de paiment</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow">cash</span>
            <span class="ml-4 flex-shrink-0"> </span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt class="text-sm font-medium text-gray-500">Auto-renew</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow flex items-center gap-1">
              <BadgeCheckIcon v-if="!$user.verified" class="w-6 h-6 text-mantis-500" />
              <XCircleIcon v-else class="w-6 h-6 text-red-500" />
            </span>
            <span class="ml-4 flex-shrink-0"> </span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt class="text-sm font-medium text-gray-500">Début de souscription</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow flex items-center gap-1">
              {{ $user.subscription.date_start }}
              ({{ $filters.timeAgo($user.subscription.date_start) }})
              <!--              <BadgeCheckIcon v-if="$user.phone.verified" class="w-6 h-6 text-mantis-500"/>-->
              <!--              <XCircleIcon v-else class="w-6 h-6 text-red-500"/>-->
            </span>
            <span class="ml-4 flex-shrink-0"> </span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt class="text-sm font-medium text-gray-500">Fin de souscription</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow"
              >{{ $user.subscription.date_end }} ({{
                $filters.timeAgo($user.subscription.date_end)
              }})</span
            >

            <span class="ml-4 flex-shrink-0"> </span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt class="text-sm font-medium text-gray-500">Pack</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow"
              >{{ $user.subscription.pack.name }} (
              <a href="#" class="link">voir les packs</a> )</span
            >
            <span class="ml-4 flex-shrink-0"> </span>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
  import { PaperClipIcon, BadgeCheckIcon, XCircleIcon } from '@heroicons/vue/solid'
  import { InformationCircleIcon } from '@heroicons/vue/outline'

  export default {
    name: 'Subscription.vue',
    components: { InformationCircleIcon, BadgeCheckIcon, PaperClipIcon, XCircleIcon },
    data() {
      return {
        showRenewAlert: false
      }
    },
    mounted() {
      if (this.$user.subscription.date_end) {
        if (this.$filters.diffInDays(this.$user.subscription.date_end) <= 14) {
          this.showRenewAlert = true
        }
      }
    }
  }
</script>

<style scoped></style>
