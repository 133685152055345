<template>
  <div
    class="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex justify-center items-center"
    v-if="hasReachedMaxAccountNumber"
  >
    <div class="text-center">
      <UploadIcon class="mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">Mettez à niveau</h3>
      <p class="mt-1 text-sm text-gray-500">
        Votre plan de souscription ne permet pas l'ajout de comptes.
      </p>
      <div class="mt-6">
        <button
          type="button"
          class="inline-flex mr-2 items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
        >
          <UploadIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Mettre à niveau
        </button>
        <button
          @click="$router.go(-1)"
          type="button"
          class="inline-flex items-center rounded-md border border-primary-600 border-2 px-4 py-2 text-sm font-medium text-primary-600 shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
        >
          <ArrowLeftIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Retour
        </button>
      </div>
    </div>
  </div>

  <CreateAccountForm v-else />
</template>

<script>
  import { PlusIcon, ArrowLeftIcon, UploadIcon } from '@heroicons/vue/outline'
  import CreateAccountForm from '@/components/accounts/CreateAccountForm.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'Create.vue',
    components: { PlusIcon, ArrowLeftIcon, UploadIcon, CreateAccountForm },
    data() {
      return {
        hasReachedMaxAccountNumber: false
      }
    },
    mounted() {
      console.log(this.$user.subscription.pack.max_accounts, this.accounts.length)
      this.hasReachedMaxAccountNumber =
        this.$user.subscription.pack.max_accounts <= this.accounts.length
    },
    computed: {
      ...mapState('accounts', ['accounts'])
    }
  }
</script>

<style scoped></style>
