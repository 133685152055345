<template>
  <form @submit.prevent="handleSubmit">
    <div v-for="field in fields" :key="field.name" class="py-4">
      <label class="block text-sm font-medium text-gray-700" :for="field.name">
        {{ $t(field.label) }}
      </label>

      <!-- Render Input -->
      <input
        v-if="field.type === 'text' || field.type === 'password' || field.type === 'email'"
        :type="field.type"
        :name="field.name"
        v-model="formData[field.name]"
        v-bind="getFieldAttributes(field.attributes)"
        class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />

      <!-- Render Select -->
      <select
        v-if="field.type === 'select'"
        :name="field.name"
        v-model="formData[field.name]"
        v-bind="getFieldAttributes(field.attributes)"
        class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
      >
        <option v-for="option in field.options" :key="option.value" :value="option.value">
          {{ $t(option.label) }}
        </option>
      </select>

      <!-- Render Checkbox -->
      <div v-if="field.type === 'checkbox'" class="flex items-center">
        <input
          type="checkbox"
          :name="field.name"
          v-model="formData[field.name]"
          v-bind="getFieldAttributes(field.attributes)"
          class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
        />
        <label :for="field.name" class="ml-2 block text-sm text-gray-900">
          {{ field.label }}
        </label>
      </div>

      <!-- Render Radio -->
      <div
        v-if="field.type === 'radio'"
        class="flex items-center"
        v-for="option in field.options"
        :key="option.value"
      >
        <input
          type="radio"
          :name="field.name"
          :value="option.value"
          v-model="formData[field.name]"
          v-bind="getFieldAttributes(field.attributes)"
          class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
        />
        <label :for="field.name" class="ml-2 block text-sm text-gray-900">
          {{ $t(`labels.${option.label}`) }}
        </label>
      </div>
    </div>
    <button type="submit" class="mt-4 bg-primary-600 text-white px-4 py-2 rounded-md">
      {{ $t('general.controls.save') }}
    </button>
  </form>
</template>

<script>
  export default {
    props: {
      fields: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        formData: this.initializeFormData()
      }
    },
    methods: {
      initializeFormData() {
        const data = {}
        this.fields.forEach(field => {
          if (field.type === 'checkbox') {
            data[field.name] = false
          } else if (field.type === 'radio') {
            data[field.name] = ''
          } else {
            data[field.name] = ''
          }
        })
        return data
      },
      getFieldAttributes(attributes) {
        return attributes.reduce((acc, attr) => {
          acc[attr] = true
          return acc
        }, {})
      },
      handleSubmit() {
        this.$emit('handleSubmit', this.formData)
      }
    },
    watch: {
      fields: {
        handler() {
          this.formData = this.initializeFormData()
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style scoped>
  /* Add your styles here */
</style>
