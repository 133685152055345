<template>
  <div class="max-w-md mx-auto sm:max-w-3xl mt-10">
    <div>
      <EmptyState :entity="$t('entities.user', 30)" icon="users" />
      <form class="mt-6 sm:flex sm:items-center" action="#">
        <label for="emails" class="sr-only">Email addresses</label>
        <div class="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
          <input
            type="text"
            name="emails"
            id="emails"
            class="focus:ring-primary-500 focus:border-primary-500 block w-full pr-32 sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter an email"
          />
          <div class="absolute inset-y-0 right-0 flex items-center">
            <span class="h-4 w-px bg-gray-200" aria-hidden="true" />
            <label for="role" class="sr-only">Role</label>
            <select
              id="role"
              name="role"
              class="focus:ring-primary-500 focus:border-primary-500 h-full py-0 pl-4 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
            >
              <option>Can edit</option>
              <option>Can view</option>
            </select>
          </div>
        </div>
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
          <button
            type="submit"
            class="block w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Send invite
          </button>
        </div>
      </form>
    </div>
    <div class="mt-10">
      <h3 class="text-xs font-semibold text-gray-500 uppercase tracking-wide">
        Recommended team members
      </h3>
      <ul role="list" class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
        <li v-for="(person, personIdx) in users" :key="personIdx">
          <button
            type="button"
            class="group p-2 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <span class="min-w-0 flex-1 flex items-center space-x-3">
              <span class="block flex-shrink-0">
                <img class="h-10 w-10 rounded-full" :src="person.imageUrl" alt="" />
              </span>
              <span class="block min-w-0 flex-1">
                <span class="block text-sm font-medium text-gray-900 truncate">{{
                  person.name
                }}</span>
                <span class="block text-sm font-medium text-gray-500 truncate">{{
                  person.role
                }}</span>
              </span>
            </span>
            <span class="flex-shrink-0 h-10 w-10 inline-flex items-center justify-center">
              <PlusIcon
                class="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import http from '@/http/index.js'
  import { PlusIcon } from '@heroicons/vue/outline'
  import EmptyState from '@/components/hints/EmptyState.vue'
  export default {
    data() {
      return {
        users: []
      }
    },
    mounted() {
      this.fetchUsers()
    },
    components: { PlusIcon, EmptyState },
    methods: {
      fetchUsers() {
        http
          .get('customer/users')
          .then(response => {
            console.log(response.data)
          })
          .catch(errors => {
            console.log(errors)
          })
      }
    }
  }
</script>
