import axios from 'axios'
import store from '../store/index.js'

axios.defaults.baseURL = import.meta.env.VITE_API_ENDPOINT

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT
})

instance.interceptors.request.use(config => {
  config.headers['Authorization'] = `Bearer ${store.state.auth.token}`
  config.headers['lang'] = localStorage.getItem('locale')
  return config
})

instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('auth/logout').then(() => location.reload())
    }
    if (error.response.status === 500) {
      store.dispatch('toast/showToast', {
        type: 'danger',
        title: 'Erreur système.',
        body: 'Il semble y avoir une erreur avec le serveur.'
      })
    } else {
      throw error
    }
  }
)

export default instance
