<template>
  <main class="flex flex-col gap-4 p-4">
    <div class="lg:border-t lg:border-b lg:border-gray-200 bg-white">
      <nav class="mx-auto" aria-label="Progress">
        <ol
          role="list"
          class="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none"
        >
          <li
            v-for="(step, stepIdx) in subNavigation"
            :key="step.id"
            class="relative overflow-hidden lg:flex-1"
          >
            <div
              :class="[
                stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                stepIdx === subNavigation.length - 1 ? 'border-t-0 rounded-b-md' : '',
                'border border-gray-200 overflow-hidden lg:border-0'
              ]"
            >
              <a
                v-if="step.status === 'complete'"
                :href="step.href"
                class="group"
                @click="setCurrentAndActiveTab(step)"
              >
                <span
                  class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    stepIdx !== 0 ? 'lg:pl-9' : '',
                    'px-6 py-5 flex items-start text-sm font-medium'
                  ]"
                >
                  <span class="flex-shrink-0">
                    <span
                      class="w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full"
                    >
                      <CheckIcon class="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                  </span>
                  <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                    <span class="text-xs font-semibold tracking-wide uppercase">{{
                      step.name
                    }}</span>
                    <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                  </span>
                </span>
              </a>
              <a
                v-else-if="step.status === 'current'"
                :href="step.href"
                aria-current="step"
                @click="setCurrentAndActiveTab(step)"
              >
                <span
                  class="absolute top-0 left-0 w-1 h-full bg-primary-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    stepIdx !== 0 ? 'lg:pl-9' : '',
                    'px-6 py-5 flex items-start text-sm font-medium'
                  ]"
                >
                  <span class="flex-shrink-0">
                    <span
                      class="w-10 h-10 flex items-center justify-center border-2 border-primary-600 rounded-full"
                    >
                      <component
                        :is="step.icon"
                        class="flex-shrink-0 -mt-0.5 h-6 w-6 text-primary-400"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                    <span class="text-xs font-semibold text-primary-600 tracking-wide uppercase">{{
                      step.name
                    }}</span>
                    <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                  </span>
                </span>
              </a>
              <a v-else :href="step.href" class="group" @click="setCurrentAndActiveTab(step)">
                <span
                  class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    stepIdx !== 0 ? 'lg:pl-9' : '',
                    'px-6 py-5 flex items-start text-sm font-medium'
                  ]"
                >
                  <span class="flex-shrink-0">
                    <span
                      class="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full"
                    >
                      <component
                        :is="step.icon"
                        class="flex-shrink-0 -mt-0.5 h-6 w-6 text-primary-400"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                    <span class="text-xs font-semibold text-gray-500 tracking-wide uppercase">{{
                      step.name
                    }}</span>
                    <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                  </span>
                </span>
              </a>
              <template v-if="stepIdx !== 0">
                <div class="hidden absolute top-0 left-0 w-3 inset-0 lg:block" aria-hidden="true">
                  <svg
                    class="h-full w-full text-gray-300"
                    viewBox="0 0 12 82"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0.5 0V31L10.5 41L0.5 51V82"
                      stroke="currentcolor"
                      vector-effect="non-scaling-stroke"
                    />
                  </svg>
                </div>
              </template>
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <ErrorComponent :errors="errors" v-if="errors" />
    <div class="rounded border border-gray-100 shadow bg-white p-4">
      <component
        v-if="activeTab"
        :key="activeTab"
        @goToStep="setCurrentAndActiveTabById"
        :is="activeTab"
      />
      <div v-else>
        <div>
          <h1 class="text-lg leading-6 font-medium text-gray-900">Informations générales</h1>
          <p class="mt-1 text-sm text-gray-500">
            Commençons par remplir les informations ci-dessous pour créer votre nouvel événement.
          </p>
        </div>

        <Summary @saveEvent="saveEvent" />
      </div>
    </div>
  </main>
</template>

<script>
  import { shallowRef, markRaw, ref } from 'vue'
  import {
    CogIcon,
    PhotographIcon,
    UserGroupIcon,
    ClipboardCheckIcon,
    CheckIcon
  } from '@heroicons/vue/solid'
  import General from '@/components/events/tabs/General.vue'
  import Branding from '@/components/events/tabs/Branding.vue'
  import Inscriptions from '@/components/events/tabs/Inscriptions.vue'
  import Summary from '@/components/events/tabs/Summary.vue'
  import { useI18n } from 'vue-i18n'
  import http from '@/http'
  import { useStore } from 'vuex'
  import ErrorComponent from '@/components/alerts/ErrorComponent.vue'
  import { useRouter } from 'vue-router'

  export default {
    name: 'Create',
    components: {
      ErrorComponent,
      CheckIcon,
      Summary
    },
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      const mobileMenuOpen = shallowRef(false)
      const activeTab = shallowRef(markRaw(General))
      const router = useRouter()

      const subNavigation = shallowRef([
        {
          id: 1,
          name: t('general.labels.general'),
          description:
            "Configurez les détails de base de l'événement, y compris le nom, la description, les dates et le type.",
          href: '#',
          icon: CogIcon,
          current: true,
          status: 'current',
          component: General
        },
        {
          id: 2,
          name: t('general.labels.branding'),
          description:
            "Personnalisez l'apparence de votre événement en définissant les couleurs et le style visuel.",
          href: '#',
          icon: PhotographIcon,
          current: false,
          component: Branding,
          status: 'upcoming'
        },
        {
          id: 3,
          name: t('general.labels.settings'),
          description: "Gérez la liste des participants et configurez les options d'inscription.",
          href: '#',
          icon: UserGroupIcon,
          current: false,
          component: Inscriptions,
          status: 'upcoming'
        },
        {
          id: 4,
          name: t('general.labels.summary'),
          description: 'Vérifiez tous les détails et finalisez la création de votre événement.',
          href: '#',
          icon: ClipboardCheckIcon,
          current: false,
          status: 'upcoming'
        }
      ])
      const errors = shallowRef(null)
      const setActiveTab = component => {
        activeTab.value = markRaw(component)
      }

      function setCurrentAndActiveTabById(id) {
        const currentIndex = subNavigation.value.findIndex(navItem => navItem.id === id)
        const item = subNavigation.value.find(navItem => navItem.id === id)

        subNavigation.value.forEach((navItem, index) => {
          if (index < currentIndex) {
            navItem.status = 'complete'
          } else if (index === currentIndex) {
            navItem.status = 'current'
            navItem.current = true
          } else {
            navItem.status = 'upcoming'
            navItem.current = false
          }
        })

        setActiveTab(item.component)
      }

      function setCurrentAndActiveTab(item) {
        const currentIndex = subNavigation.value.findIndex(navItem => navItem === item)

        subNavigation.value.forEach((navItem, index) => {
          if (index < currentIndex) {
            navItem.status = 'complete'
          } else if (index === currentIndex) {
            navItem.status = 'current'
            navItem.current = true
          } else {
            navItem.status = 'upcoming'
            navItem.current = false
          }
        })

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })

        setActiveTab(item.component)
      }

      const saveEvent = () => {
        http
          .post('/customer/events', store.state.events.newEvent)
          .then(response => {
            store.dispatch('events/resetEvent')
            router.push({ name: 'ShowEvent', params: { id: response.data.id } })
          })
          .catch(e => {
            errors.value = e.response.data[0]
          })
      }

      return {
        subNavigation,
        mobileMenuOpen,
        activeTab,
        errors,
        saveEvent,
        setCurrentAndActiveTab,
        setCurrentAndActiveTabById
      }
    }
  }
</script>

<style scoped>
  .main-content {
    max-width: 1280px;
    margin: 0 auto;
  }
</style>
