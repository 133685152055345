<template>
  <nav class="bg-white border-b border-gray-200 flex" aria-label="" v-if="pages.length">
    <ol role="list" class="w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
      <li class="flex">
        <div class="flex items-center">
          <router-link to="/" class="text-gray-400 hover:text-gray-500">
            <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            <span class="sr-only">{{ $t('home') }}</span>
          </router-link>
        </div>
      </li>
      <li v-for="page in pages" :key="page.name" class="flex">
        <div class="flex items-center">
          <svg
            class="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <a
            :href="page.href"
            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            :aria-current="page.current ? 'page' : undefined"
            >{{ page.name }}</a
          >
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
  import { HomeIcon } from '@heroicons/vue/solid'

  export default {
    name: 'Breadcrumbs',
    components: {
      HomeIcon
    },
    data() {
      return {
        pages: []
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
          this.syncBreadCrumbs()
        }
      }
    },
    methods: {
      syncBreadCrumbs() {
        if (this.$route.meta.breadcrumbs) {
          this.pages = this.$route.meta.breadcrumbs
        } else {
          this.pages = []
        }
      }
    },
    created() {
      this.syncBreadCrumbs()
    }
  }
</script>

<style scoped></style>
