<template>
  {{ $t('general.hints.download_started') }}
</template>

<script>
  import http from '@/http/index.js'

  export default {
    mounted() {
      this.downloadFile()
    },
    methods: {
      async downloadFile() {
        const fileUrl = this.$route.query.fileUrl // accéder au nom du fichier depuis les paramètres de la route

        try {
          const response = await http.get(`/customer/download/${fileUrl}`, {
            responseType: 'blob'
          })

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileUrl) // nom du fichier
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.close()
        } catch (error) {
          console.error('Erreur lors du téléchargement du fichier:', error)
        }
      }
    }
  }
</script>
