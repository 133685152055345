<template>
  <button
    type="button"
    :class="[`button-${type}`, customClasses, 'flex flex-row items-center gap-2']"
  >
    <i v-if="icon" :class="[`${icon}`]"></i>
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: 'GenericButton',
    props: {
      customClasses: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'primary'
      },
      icon: {
        type: String,
        default: null
      }
    }
  }
</script>
