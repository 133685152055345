<template>
  <div class="mt-6 max-w-5xl">
    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.account_id') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ $store.state.events.newEvent.account_id }}</dd>
      </div>

      <div class="sm:col-span-2">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.name') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ $store.state.events.newEvent.name }}</dd>
      </div>

      <div class="sm:col-span-2">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.description') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ $store.state.events.newEvent.description }}</dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.type') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ $store.state.events.newEvent.type }}</dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.date_start') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ $store.state.events.newEvent.start_date }}</dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.date_end') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ $store.state.events.newEvent.end_date }}</dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.max_attendees') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ $store.state.events.newEvent.settings.max_attendees }}
        </dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.registration_open') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            $store.state.events.newEvent.settings.registration_open
              ? $t('labels.yes')
              : $t('labels.no')
          }}
        </dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.venue') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ $store.state.events.newEvent.venue }}</dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.primary_color') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <span
            class="inline-block w-6 h-6"
            :style="{ backgroundColor: $store.state.events.newEvent.branding.primary_color }"
          ></span>
        </dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.secondary_color') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <span
            class="inline-block w-6 h-6"
            :style="{ backgroundColor: $store.state.events.newEvent.branding.secondary_color }"
          ></span>
        </dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.accent_color') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <span
            class="inline-block w-6 h-6"
            :style="{ backgroundColor: $store.state.events.newEvent.branding.accent_color }"
          ></span>
        </dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.logo_url') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            $store.state.events.newEvent.branding.logo_url
              ? $store.state.events.newEvent.branding.logo_url
              : $t('labels.none')
          }}
        </dd>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">{{ $t('labels.cover_image') }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            $store.state.events.newEvent.branding.cover_image
              ? $store.state.events.newEvent.branding.cover_image
              : $t('labels.none')
          }}
        </dd>
      </div>
    </dl>

    <GenericButton class="mt-4" icon="fas fa-save" type="primary" @click="saveEvent">
      {{ $t('general.controls.save') }}
    </GenericButton>
  </div>
</template>

<script>
  import GenericButton from '@/components/buttons/GenericButton.vue'

  export default {
    name: 'Summary',
    components: { GenericButton },
    methods: {
      saveEvent() {
        this.$emit('saveEvent')
      }
    }
  }
</script>

<style scoped>
  /* Ajoutez des styles supplémentaires ici si nécessaire */
</style>
