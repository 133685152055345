<template>
  <div role="alert" :class="classes">
    <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2 flex justify-between">
      {{ errors[0].message }}

      <i class="fal fa-times self-center"></i>
    </div>
    <div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
      <ul role="list" class="list-disc space-y-1 pl-5">
        <li v-for="(message, key) in errors[0].errors" :key="key" class="text-danger-700">
          {{ message }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { ExclamationIcon } from '@heroicons/vue/solid'

  export default {
    name: 'ErrorComponent',
    props: ['errors', 'classes'],
    components: { ExclamationIcon }
  }
</script>
