export default {
  namespaced: true,
  state: {
    accounts: [],
    activeAccount: {},
    activeEvent: {}
  },
  mutations: {
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts
    },
    SET_ACTIVE_ACCOUNT(state, account) {
      state.activeAccount = account
    },
    SET_ACTIVE_EVENT(state, event) {
      state.activeEvent = event
    }
  },
  actions: {
    setAccounts({ commit }, accounts) {
      commit('SET_ACCOUNTS', accounts)
    },
    setActiveAccount({ commit }, account) {
      commit('SET_ACTIVE_ACCOUNT', account)
    },
    setActiveEvent({ commit }, event) {
      commit('SET_ACTIVE_EVENT', event)
    }
  }
}
