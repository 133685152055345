import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
import ar from '@/locales/ar.json'
let defaultLocale

if (localStorage.getItem('locale')) {
  defaultLocale = localStorage.getItem('locale')
} else {
  defaultLocale = 'en'
}

const messages = {
  en,
  fr,
  ar
}

export default createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
})
