<template>
  <div class="rounded-md p-4 w-full mx-auto" v-if="loading">
    <div class="animate-pulse flex space-x-4">
      <div class="rounded-full bg-gray-400 h-10 w-10"></div>
      <div class="flex-1 space-y-6 py-1">
        <div class="h-2 bg-gray-400 rounded"></div>
        <div class="space-y-3">
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-gray-400 rounded col-span-2"></div>
            <div class="h-2 bg-gray-400 rounded col-span-1"></div>
          </div>
          <div class="h-2 bg-slate-400 rounded"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="attendees.length">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mb-8">
        <div class="flex justify-between items-center mb-2 p-2">
          <div class="sm:flex-auto">
            <p class="mt-2 text-sm text-gray-700"></p>
          </div>
          <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-2">
            <GenericButton icon="fal fa-user-plus" @click="showCreateAttendeeDrawer">
              {{ $t('general.controls.create') }}
            </GenericButton>
            <GenericButton icon="fas fa-upload" type="clear" @click="showimportAttendeesDrawer">
              {{ $t('general.controls.import') }}
            </GenericButton>
            <GenericGroupButton
              icon="fas fa-download"
              :mainActionLabel="$t('general.controls.export')"
              :items="menuItems"
              @main-action-click="onMainAction"
              @item-action-click="onItemAction"
            />
          </div>
        </div>
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-t-lg">
          <table class="min-w-full divide-y divide-gray-300 z-0">
            <thead class="bg-gray-50">
              <tr>
                <th></th>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  v-for="(key, value) in dataProp.settings?.available_attendee_fields"
                  :key="key"
                >
                  {{ $t(`attendee.fields.${value}`) }}
                </th>
                <th scope="col" class="py-3.5 pl-3 pr-4 sm:pr-6">
                  <span class="sr-only">{{ $t('general.controls.edit') }}</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="attendee in attendees" :key="attendee.email">
                <td class="text-sm text-center font-medium text-gray-900">
                  <i class="fas fa-qrcode text-xl"></i>
                </td>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  v-for="(key, value) in dataProp.settings?.available_attendee_fields"
                  :key="key"
                >
                  {{ attendee[value] }}
                </td>
                <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a href="#" class="text-primary-600 hover:text-primary-900"
                    >{{ $t('general.controls.edit')
                    }}<span class="sr-only">, {{ attendee.name }}</span></a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <nav
          class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
          v-if="this.last_page"
        >
          <div class="-mt-px w-0 flex-1 flex">
            <a
              href="#"
              @click="goToPage(actualPage - 1)"
              class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
            >
              <ArrowNarrowLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              {{ $t('pagination.previous') }}
            </a>
          </div>
          <div class="hidden md:-mt-px md:flex">
            <!--            <a-->
            <!--              href='#'-->
            <!--              @click='goToPage(nbr)'-->
            <!--              class='pagination-number'-->
            <!--              v-for='nbr in last_page'-->
            <!--              :class='{ active: actualPage === nbr }'-->
            <!--              :key='nbr'-->
            <!--            >-->
            <!--              {{ nbr }}-->
            <!--            </a>-->
          </div>
          <div class="-mt-px w-0 flex-1 flex justify-end">
            <a
              href="#"
              @click="goToPage(actualPage + 1)"
              class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
            >
              {{ $t('pagination.next') }}
              <ArrowNarrowRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
          </div>
        </nav>
      </div>
    </div>
    <div v-else>
      <div class="flex items-center flex-col">
        <i class="fas fa-users-slash fa-3x my-4 text-gray-400"></i>
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          {{ $t('general.hints.attendees_empty.title') }}
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          {{ $t('general.hints.attendees_empty.description') }}
        </p>
        <div class="mt-6">
          <GenericButton type="clear" @click="showCreateAttendeeDrawer" icon="fal fa-plus">
            {{
              $t('general.entityAction', {
                control: $t('general.controls.create'),
                entity: $t('entities.attendee')
              })
            }}
          </GenericButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/http/index.js'
  import { ref } from 'vue'
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions
  } from '@headlessui/vue'
  import {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    SelectorIcon,
    CheckIcon,
    PlusIcon,
    ArrowNarrowRightIcon,
    ArrowNarrowLeftIcon
  } from '@heroicons/vue/solid'
  import CreateAttendee from '@/components/events/attendees/CreateAttendee.vue'
  import GenericButton from '@/components/buttons/GenericButton.vue'
  import GenericGroupButton from '@/components/buttons/GenericGroupButton.vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import ImportAttendees from '@/components/events/attendees/ImportAttendees.vue'

  export default {
    name: 'Atterndees.vue',
    components: {
      GenericGroupButton,
      GenericButton,
      CheckCircleIcon,
      ChevronRightIcon,
      MailIcon,
      Combobox,
      SelectorIcon,
      CheckIcon,
      ArrowNarrowRightIcon,
      ArrowNarrowLeftIcon,
      ComboboxButton,
      ComboboxInput,
      ComboboxLabel,
      ComboboxOption,
      ComboboxOptions,
      PlusIcon
    },
    props: {
      dataProp: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        attendees: [],
        query: ref(''),
        selectedPerson: ref(),
        actualPage: 1,
        per_page: null,
        last_page: null,
        loading: false
      }
    },
    mounted() {
      this.fetchAttendees()
    },
    methods: {
      goToPage(pageNbr) {
        if (pageNbr >= 1 && pageNbr <= this.last_page) {
          this.actualPage = pageNbr
          this.fetchAttendees()
        }
      },
      fetchAttendees() {
        this.loading = true
        http
          .get('customer/events/' + this.$route.params.id + '/attendees', {
            params: {
              page: this.actualPage
            }
          })
          .then(response => {
            this.attendees = response.data.data
            this.per_page = response.data.meta.per_page
            this.last_page = response.data.meta.last_page
          })
          .finally(() => {
            this.loading = false
          })
      },
      showCreateAttendeeDrawer() {
        this.$store.commit('drawer/setDynamicComponent', CreateAttendee)
        this.$store.commit('drawer/setDrawerTitle', 'Inviter une personne')
        this.$store.commit('drawer/openDrawer')
      },
      showimportAttendeesDrawer() {
        this.$store.commit('drawer/setDynamicComponent', ImportAttendees)
        this.$store.commit('drawer/setDrawerTitle', "Importer une liste d'ivités")
        this.$store.commit('drawer/openDrawer')
      },
      onMainAction() {
        console.log('Main action clicked')
      }
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const onItemAction = item => {
        http
          .get(`/customer/events/${route.params.id}/attendees/export?format=${item}`)
          .then(() => {})
          .finally(() => {
            store.dispatch('toast/showToast', {
              type: 'info',
              title: t('notifications.export.successTitle', { item }),
              body: t('notifications.export.successBody', { item })
            })
          })
      }

      const menuItems = [
        {
          name: t('general.controls.exportBadges'),
          action: 'badges',
          icon: 'fa-regular fa-qrcode'
        },
        { name: t('general.controls.exportXlsx'), action: 'xlsx', icon: 'fa-regular fa-file-xls' },
        { name: t('general.controls.exportPdf'), action: 'pdf', icon: 'fa-regular fa-file-pdf' }
      ]

      return {
        menuItems,
        onItemAction
      }
    },
    computed: {
      filteredPeople() {
        const { attendees, query } = this
        if (query === '') {
          return attendees
        } else {
          return attendees.filter(person => {
            return person.name.toLowerCase().includes(query.toLowerCase())
          })
        }
      }
    }
  }
</script>

<style scoped></style>
