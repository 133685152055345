<template>
  <div
    :class="{
      'translate-x-0': isDrawerOpen,
      'translate-x-full': !isDrawerOpen,
      'left-0': $store.state.appSettings.direction === 'rtl',
      'right-0': $store.state.appSettings.direction === 'ltr'
    }"
    class="fixed top-0 w-1/4 bg-white shadow-lg z-50 -translate-x-full transition-transform delay-300 ease-in-out h-full"
  >
    <div class="h-full">
      <div class="flex justify-between items-center mb-4 bg-secondary-500 py-6 px-4">
        <div>
          <h2 class="text-xl font-semibold text-white">{{ drawerTitle }}</h2>
          <small class="text-white" v-if="drawerDescription" v-text="drawerDescription"></small>
        </div>
        <button @click="closeDrawer" class="text-white focus:outline-none">
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M18 6L6 18M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <component @showSubDrawer="showSubDrawer" class="m-4 border-l border-gray-50" :is="dynamicComponentName" />
    </div>
  </div>
  <div
    :class="{
      'translate-x-0 right-1/4': isSubDrawerOpen,
      'translate-x-full': !isSubDrawerOpen,
      'left-0': $store.state.appSettings.direction === 'rtl',
      'right-0': $store.state.appSettings.direction === 'ltr'
    }"
    class="fixed top-0 w-1/4 right-0 bg-white shadow-lg z-40 -translate-x-full transition-transform delay-150 ease-in-out h-full"
  >
    <div class="h-full">
      <div class="flex justify-between items-center mb-4 bg-white py-6 px-4">
        <div>
        </div>
        <button @click="hideSubDrawer" class="text-secondary-800 focus:outline-none">
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M18 6L6 18M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <component class="m-4" :is="subDrawerComponent" />
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue';

export default {
  computed: {
    isDrawerOpen() {
      return this.$store.state.drawer.isDrawerOpen;
    },
    dynamicComponentName() {
      return markRaw(this.$store.state.drawer.dynamicComponentName);
    },
    drawerTitle() {
      return this.$store.state.drawer.drawerTitle;
    },
    drawerDescription() {
      return this.$store.state.drawer.drawerDescription;
    },
  },
  data(){
    return {
      isSubDrawerOpen: false,
      subDrawerComponent: null
    }
  },
  methods: {
    closeDrawer() {
      this.$store.commit('drawer/closeDrawer');
    },
    showSubDrawer(component){
      this.isSubDrawerOpen = true;
      this.subDrawerComponent = component;
    },
    hideSubDrawer(){
      console.log('salut');
      this.isSubDrawerOpen = false;
      this.subDrawerComponent = null;
    }
  }
}
</script>

<style scoped>
.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}
</style>
